import axios from 'axios';

export default (
  id,
  landlordName,
  landlordAddress,
  landlordTel,
  landlordEmail,
  landlordAgentName,
  landlordAgentAddress,
  landlordAgentTel,
  landlordAgentEmail,
  tenantAgentName,
  tenantAgentAddress,
  tenantAgentTel,
  tenantAgentEmail,
) => {
  const preparedData = {};

  preparedData.landlord_name = landlordName !== '' && landlordName !== null ? landlordName : null;
  preparedData.landlord_address = landlordAddress !== '' && landlordAddress !== null ? landlordAddress : null;
  preparedData.landlord_tel = landlordTel !== '' && landlordTel !== null ? landlordTel : null;
  preparedData.landlord_email = landlordEmail !== '' && landlordEmail !== null ? landlordEmail : null;

  preparedData.landlord_agent_name = landlordAgentName !== '' && landlordAgentName !== null ? landlordAgentName : null;
  preparedData.landlord_agent_address =
    landlordAgentAddress !== '' && landlordAgentAddress !== null ? landlordAgentAddress : null;
  preparedData.landlord_agent_tel = landlordAgentTel !== '' && landlordAgentTel !== null ? landlordAgentTel : null;
  preparedData.landlord_agent_email =
    landlordAgentEmail !== '' && landlordAgentEmail !== null ? landlordAgentEmail : null;

  preparedData.tenant_agent_name = tenantAgentName !== '' && tenantAgentName !== null ? tenantAgentName : null;
  preparedData.tenant_agent_address =
    tenantAgentAddress !== '' && tenantAgentAddress !== null ? tenantAgentAddress : null;
  preparedData.tenant_agent_tel = tenantAgentTel !== '' && tenantAgentTel !== null ? tenantAgentTel : null;
  preparedData.tenant_agent_email = tenantAgentEmail !== '' && tenantAgentEmail !== null ? tenantAgentEmail : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    contact: preparedData,
  });
};
