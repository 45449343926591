import { REMEDIATION_WORKS_COPY, DEED_OF_CERTIFICATE_COPY, LANDLORDS_CERTIFICATE_COPY } from '@/values/fileSlugs';

export default {
  remediationWorksCopy: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: REMEDIATION_WORKS_COPY, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'remediationWorksCopy',
      answers: ['ATF_ATTACHED', 'ATF_TO_FOLLOW'],
      mainText: 'Have any remediation works on the building been proposed or carried out?',
      documentText: 'Please provide details of the remediation works proposed and evidence of any carried out.',
    },
  },
  deedOfCertificateCopy: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: DEED_OF_CERTIFICATE_COPY, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'deedOfCertificateCopy',
      answers: ['ATF_ATTACHED', 'ATF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of the leaseholder deed of certificate and the accompanying evidence.',
    },
  },
  landlordsCertificateCopy: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: LANDLORDS_CERTIFICATE_COPY, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'landlordsCertificateCopy',
      answers: ['ATF_ATTACHED', 'ATF_TO_FOLLOW'],
      mainText: 'Has the seller received a Landlord’s Certificate and the accompanying evidence?',
      documentText: 'Please supply a copy of the Landlord’s Certificate and the accompanying evidence.',
    },
  },
};
