<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/consents/mutations';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchConsents from '@/api/buildings/patchConsents';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import consents from '@/functions/supportingDocuments/consents';

/**
 * Options for the consents.
 */
const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const optionsForCopyFields = {
  ETFLD_ENCLOSED: 'Attached',
  ETFLD_TO_FOLLOW: 'To follow',
  ETFLD_LOST: 'Lost',
  ETFLD_DETAILS: 'Details',
};

export default {
  name: 'Consents',
  components: { SimpleQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_leaseTermsChange: (state) => state.building.consents.leaseTermsChange,
      s_leaseTermsChangeDocument: (state) => state.building.consents.leaseTermsChangeDocument,
      s_leaseTermsChangeDescription: (state) => state.building.consents.leaseTermsChangeDescription,
      s_buildingId: (state) => state.buildingId,
    }),
    leaseTermsChange: {
      get() {
        return {
          answer: this.s_leaseTermsChange,
        };
      },
      set(value) {
        this[`${mutations.CONSENTS_PREFIX}${mutations.CONSENTS_SET_LEASE_TERMS_CHANGE}`](value.answer);
      },
    },
    leaseTermsChangeDocument: {
      get() {
        return {
          answer: this.s_leaseTermsChangeDocument,
        };
      },
      set(value) {
        this[`${mutations.CONSENTS_PREFIX}${mutations.CONSENTS_SET_LEASE_TERMS_CHANGE_DOCUMENT}`](value.answer);
      },
    },
    leaseTermsChangeDescription: {
      get() {
        return this.s_leaseTermsChangeDescription;
      },
      set(value) {
        this[`${mutations.CONSENTS_PREFIX}${mutations.CONSENTS_SET_LEASE_TERMS_CHANGE_DESCRIPTION}`](value);
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      optionsForCopyFields,
      consents,
    };
  },
  methods: {
    ...mapMutations([
      `${mutations.CONSENTS_PREFIX}${mutations.CONSENTS_SET_LEASE_TERMS_CHANGE}`,
      `${mutations.CONSENTS_PREFIX}${mutations.CONSENTS_SET_LEASE_TERMS_CHANGE_DOCUMENT}`,
      `${mutations.CONSENTS_PREFIX}${mutations.CONSENTS_SET_LEASE_TERMS_CHANGE_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate({ silent: false });
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchConsents(
        this.s_buildingId,
        this.s_leaseTermsChange,
        this.s_leaseTermsChangeDocument,
        this.s_leaseTermsChangeDescription,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Consents</h2>

      <div class="col-md-12">
        <div class="section-note">
          <p><b>Note to seller:</b> A consent may be given in a formal document, a letter or orally.</p>
        </div>
      </div>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="leaseTermsChangeLabelName"
            title="Is the seller aware of any changes in the terms of the lease or of the landlord giving any consents under the lease?"
            v-model="leaseTermsChange"
            validationFieldName="lease terms сhange"
          />
        </div>

        <div class="col-md-12" v-if="leaseTermsChange.answer">
          <simple-question
            :dropdownOptions="optionsForCopyFields"
            labelName="leaseTermsChangeDocumentLabelName"
            title="Please supply a copy or, if not in writing, please give details"
            v-model="leaseTermsChangeDocument"
            validationFieldName="lease terms change document"
          />

          <inline-file-uploader
            v-if="leaseTermsChangeDocument.answer === 'ETFLD_ENCLOSED'"
            :requiredDocument="consents.leaseTermsChangeDocument"
          />
        </div>

        <div class="col-md-12" v-if="leaseTermsChangeDocument.answer === 'ETFLD_DETAILS' && leaseTermsChange.answer">
          <validation-provider name="lease terms change description" rules="required|min:3" v-slot="validationContext">
            <b-form-textarea
              v-model="leaseTermsChangeDescription"
              rows="4"
              :state="getValidationState(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
