<script>
import GetTrackingEvents from '@/api/trackingEvents/getTrackingEvents';
import PostTrackingEvents from '@/api/trackingEvents/postTrackingEvents';
import Modal from '@/components/Modal';

export default {
  name: 'FormChangelogComponent',
  components: { Modal },
  data() {
    return {
      checked: false,

      showChangelogModal: false,
      lockedCheckbox: false,
      loader: false,
      cutOffDate: '2023-02-14T14:30:00',
      requiredEvents: {
        startedTa7: 'started_ta7',
        completedTa7: 'completed_ta7',
        formChangelogConfirmed: 'form_changelog_confirmed',
      },
    };
  },
  props: ['entityType', 'entityId'],
  methods: {
    async checkConfirmationStatus() {
      this.loader = true;
      const confirmationEvents = Object.values(this.requiredEvents);
      await GetTrackingEvents(this.entityId, confirmationEvents)
        .then((response) => {
          let count = response.data.count;
          if (count > 0) {
            // Check if form started and not completed before changelog
            // Check if already not confirmed
            const results = response.data.results;
            const ta7StartedBefore = results.filter((v) => {
              return v.event_slug === 'started_ta7' && new Date(v.created_at) < new Date(this.cutOffDate);
            });
            const ta7Completed = results.filter((v) => {
              return v.event_slug === 'completed_ta7';
            });
            const ta7ChangelogConfirmed = results.filter((v) => {
              return v.event_slug === 'form_changelog_confirmed';
            });

            if (ta7StartedBefore.length > 0 && !ta7Completed.length > 0 && !ta7ChangelogConfirmed.length > 0) {
              this.showChangelogModal = true;
            }
          }
        })
        .catch((err) => console.debug(err));

      this.loader = false;
    },
    async updateConfirmationStatus() {
      let trackingEvent = {
        product_slug: this.entityType,
        entity_id: this.entityId,
        event_slug: this.requiredEvents.formChangelogConfirmed,
        metadata: '{ "form": "TA7" }',
      };
      await PostTrackingEvents(trackingEvent)
        .then(() => {
          this.loader = false;
          this.showChangelogModal = false;
        })
        .catch((err) => console.debug(err));
    },
  },
  mounted() {
    this.checkConfirmationStatus();
  },
};
</script>

<template>
  <modal
    v-if="showChangelogModal"
    :show-buttons="true"
    closeButtonText="Close"
    :fullModalWidth="false"
    @proceed="updateConfirmationStatus"
  >
    <div class="text-center">
      <i class="icon-info3 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
      <h3 style="font-size: 22px">TA7 - Leasehold Information Form Update</h3>

      <p>We would like to inform you that:</p>
      <p><strong>TA7 - Leasehold Information Form (2nd edition) - © Law Society 2009</strong></p>
      <p>has been updated to a new version:</p>
      <p><strong>TA7 - Leasehold Information Form (3rd edition) - © Law Society 2023</strong></p>
      <h3 style="font-size: 22px">Summary of changes:</h3>
      <p><strong>1. The property</strong> - questions c), d), e) have been added</p>
      <p><strong>2. Ownership and management</strong> - new section added</p>
      <p>
        <strong>3. Relevant documents</strong> - section has been moved from position 2 to position 3 and updated with
        new questions
      </p>
      <p><strong>4. Contact details</strong> - the address has been changed from a text field to a form</p>
      <p><strong>5. Maintenance and service charges</strong> - added new questions</p>
      <p><strong>11. Building safety, cladding and the leaseholder deed of certificate</strong> - new section added</p>
      <p>
        Your current progress is stored on a page by page basis. On each page you can save your progress and come back
        later.
      </p>
    </div>
  </modal>
</template>
