<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/leasehold-notices/mutations';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchLeaseholdNotices from '@/api/buildings/patchLeaseholdNotices';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import leaseholdNotices from '@/functions/supportingDocuments/leaseholdNotices';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const optionsForCopyFields = {
  ETFL_ENCLOSED: 'Attached',
  ETFL_TO_FOLLOW: 'To follow',
  ETFL_LOST: 'Lost',
};

export default {
  name: 'LeaseholdNotices',
  components: { SimpleQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_saleNotice: (state) => state.building.leaseholdNotices.saleNotice,
      s_saleNoticeCopy: (state) => state.building.leaseholdNotices.saleNoticeCopy,
      s_generalNotice: (state) => state.building.leaseholdNotices.generalNotice,
      s_generalNoticeCopy: (state) => state.building.leaseholdNotices.generalNoticeCopy,
      s_buildingId: (state) => state.building.buildingId,
    }),
    saleNotice: {
      get() {
        return {
          answer: this.s_saleNotice,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_NOTICES_PREFIX}${mutations.LEASEHOLD_NOTICE_SET_SALE_NOTICE}`](value.answer);
      },
    },
    saleNoticeCopy: {
      get() {
        return {
          answer: this.s_saleNoticeCopy,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_NOTICES_PREFIX}${mutations.LEASEHOLD_NOTICE_SET_SALE_NOTICE_COPY}`](value.answer);
      },
    },
    generalNotice: {
      get() {
        return {
          answer: this.s_generalNotice,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_NOTICES_PREFIX}${mutations.LEASEHOLD_NOTICE_SET_GENERAL_NOTICE}`](value.answer);
      },
    },
    generalNoticeCopy: {
      get() {
        return {
          answer: this.s_generalNoticeCopy,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_NOTICES_PREFIX}${mutations.LEASEHOLD_NOTICE_SET_GENERAL_NOTICE_COPY}`](
          value.answer,
        );
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      optionsForCopyFields,
      leaseholdNotices,
    };
  },
  methods: {
    ...mapMutations([
      `${mutations.LEASEHOLD_NOTICES_PREFIX}${mutations.LEASEHOLD_NOTICE_SET_SALE_NOTICE}`,
      `${mutations.LEASEHOLD_NOTICES_PREFIX}${mutations.LEASEHOLD_NOTICE_SET_SALE_NOTICE_COPY}`,
      `${mutations.LEASEHOLD_NOTICES_PREFIX}${mutations.LEASEHOLD_NOTICE_SET_GENERAL_NOTICE}`,
      `${mutations.LEASEHOLD_NOTICES_PREFIX}${mutations.LEASEHOLD_NOTICE_SET_GENERAL_NOTICE_COPY}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchLeaseholdNotices(
        this.s_buildingId,
        this.s_saleNotice,
        this.s_saleNoticeCopy,
        this.s_generalNotice,
        this.s_generalNoticeCopy,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Notices</h2>

      <div class="col-md-12">
        <div class="section-note">
          <p><b>Note:</b> A notice may be in a printed form or in the form of a letter.</p>
        </div>
      </div>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="saleNoticeLabelName"
            title="Has the seller received a notice that the landlord wants to sell the building?"
            v-model="saleNotice"
            validationFieldName="sale notice"
          />
        </div>
        <!-- /question 1 end -->

        <!-- Question 2 -->
        <div v-if="saleNotice.answer" class="col-md-12">
          <simple-question
            :dropdownOptions="optionsForCopyFields"
            labelName="saleNoticeCopyLabelName"
            title="Please supply a copy."
            v-model="saleNoticeCopy"
            validationFieldName="sale notice copy"
          />

          <inline-file-uploader
            v-if="saleNoticeCopy.answer === 'ETFL_ENCLOSED'"
            :requiredDocument="leaseholdNotices.saleNoticeCopy"
          />
        </div>
        <!-- /question 2 end -->

        <!-- Question 3 -->
        <div class="col-md-12 border-top my-3">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="generalNoticeLabelName"
            title="Has the seller received any other notice about the building, its use, its condition or its repair and maintenance?"
            v-model="generalNotice"
            validationFieldName="general notice"
          />
        </div>
        <!-- /question 3 end -->

        <!-- Question 4 -->
        <div v-if="generalNotice.answer" class="col-12">
          <simple-question
            :dropdownOptions="optionsForCopyFields"
            labelName="generalNoticeCopyLabelName"
            title="Please supply a copy."
            v-model="generalNoticeCopy"
            validationFieldName="general notice copy"
          />

          <inline-file-uploader
            v-if="generalNoticeCopy.answer === 'ETFL_ENCLOSED'"
            :requiredDocument="leaseholdNotices.generalNoticeCopy"
          />
        </div>
        <!-- /question 4 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
