import sortDocumentTypes from '@sort-group/sort-document-types';

import {
  LEASE_SUPPLEMENTAL_DEEDS,
  LANDLORD_REGULATIONS,
  CORRESPONDENCE,
  MAINTENANCE_INVOICES,
  GROUND_RENT_INVOICES,
  SELLER_BUILDINGS_INSURANCE,
  LANDLORD_BUILDINGS_INSURANCE,
  MANAGEMENT_MEMORANDUM,
  MANAGEMENT_CERTIFICATE,
  MANAGEMENT_ACCOUNTS,
} from '@/values/fileSlugs';

export default {
  leaseSupplementalDeeds: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: LEASE_SUPPLEMENTAL_DEEDS, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'leaseSupplementalDeeds',
      answers: ['ETFS_ENCLOSED', 'ETFS_TO_FOLLOW'],
      mainText: false,
      documentText: 'The lease and any supplemental deeds',
    },
  },
  landlordRegulations: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: LANDLORD_REGULATIONS,
    documentTypes: {
      HETAS: sortDocumentTypes.HETAS,
    },
    matchByDocumentTypes: false,
    question: {
      value: 'landlordRegulations',
      answers: ['ETFNA_ENCLOSED', 'ETFNA_FOLLOW'],
      mainText: false,
      documentText:
        'Any regulations made by the landlord or by the tenants management company additional to those in the lease',
    },
  },
  correspondence: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: CORRESPONDENCE,
    documentTypes: {
      BUILDING_REGULATIONS: sortDocumentTypes.BUILDING_REGULATIONS,
      CAVITY_WALL_INSULATION: sortDocumentTypes.CAVITY_WALL_INSULATION,
      CORGI: sortDocumentTypes.CORGI,
    },
    matchByDocumentTypes: false,
    question: {
      value: 'correspondence',
      answers: ['ETFNA_ENCLOSED', 'ETFNA_FOLLOW'],
      mainText: false,
      documentText:
        'Please supply a copy of any correspondence from the landlord, the management company and the managing agent',
    },
  },
  maintenanceInvoices: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: MAINTENANCE_INVOICES,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'maintenanceInvoices',
      answers: ['ETFNA_ENCLOSED', 'ETFNA_FOLLOW'],
      mainText: false,
      documentText: 'Maintenance or service charges for the last three years',
    },
  },
  groundRentInvoices: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: GROUND_RENT_INVOICES,
    documentTypes: {
      CORGI: sortDocumentTypes.CORGI,
    },
    matchByDocumentTypes: false,
    question: {
      value: 'groundRentInvoices',
      answers: ['ETFNA_ENCLOSED', 'ETFNA_FOLLOW'],
      mainText: false,
      documentText: 'Ground rent for the last three years',
    },
  },
  sellerBuildingsInsurance: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: SELLER_BUILDINGS_INSURANCE,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'sellerBuildingsInsurance',
      answers: ['ETF_ENCLOSED', 'ETF_FOLLOW'],
      mainText: false,
      documentText: 'Arranged by the seller and a receipt for payment of the last premium',
    },
  },
  landlordBuildingsInsurance: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: LANDLORD_BUILDINGS_INSURANCE,
    documentTypes: {
      NAPIT: sortDocumentTypes.NAPIT,
    },
    matchByDocumentTypes: false,
    question: {
      value: 'landlordBuildingsInsurance',
      answers: ['ETF_ENCLOSED', 'ETF_FOLLOW'],
      mainText: false,
      documentText: 'Arranged by the landlord or management company and the schedule for the current year',
    },
  },
  managementMemorandum: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: MANAGEMENT_MEMORANDUM,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'managementMemorandum',
      answers: ['ETF_ENCLOSED', 'ETF_FOLLOW'],
      mainText: false,
      documentText: 'The Memorandum and Articles of Association',
    },
  },
  managementCertificate: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: MANAGEMENT_CERTIFICATE,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'managementCertificate',
      answers: ['ETF_ENCLOSED', 'ETF_FOLLOW'],
      mainText: false,
      documentText: 'The share or membership certificate',
    },
  },
  managementAccounts: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: MANAGEMENT_ACCOUNTS,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'managementAccounts',
      answers: ['ETF_ENCLOSED', 'ETF_FOLLOW'],
      mainText: false,
      documentText: 'The company accounts for the past three years',
    },
  },
};
