//import sortDocumentTypes from '@sort-group/sort-document-types';

import { SALE_NOTICE_COPY, GENERAL_NOTICE_COPY } from '@/values/fileSlugs';

export default {
  saleNoticeCopy: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: SALE_NOTICE_COPY, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'saleNoticeCopy',
      answers: ['ETFL_ENCLOSED', 'ETFL_TO_FOLLOW'],
      mainText: false,
      documentText: 'Notice that the landlord wants to sell the building',
    },
  },
  generalNoticeCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: GENERAL_NOTICE_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'generalNoticeCopy',
      answers: ['ETFL_ENCLOSED', 'ETFL_TO_FOLLOW'],
      mainText: false,
      documentText: 'Notice about the building, its use, its condition or its repair and maintenance',
    },
  },
};
