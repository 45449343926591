<script>
import isEqual from 'lodash/isEqual';

const yesOrNo = [
  { value: '', text: 'Please choose', disabled: true, selected: true },
  { value: true, text: 'Yes' },
  { value: false, text: 'No' },
];

export default {
  name: 'OpenQuestion',
  props: {
    titleNumberOrLetter: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    validationFieldName: {
      type: String,
      required: true,
    },
    labelName: {
      type: String,
      required: true,
    },
    dropdownName: {
      type: String,
      required: true,
    },
    textAreaName: {
      type: String,
      required: true,
    },
    detailsName: {
      type: String,
      required: false,
      default: () => 'details',
    },
    descriptionTitle: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      default: () => ({
        answer: '',
        description: null,
      }),
    },
  },
  data() {
    return {
      chosenAnswer: typeof this.value.answer === 'boolean' ? this.value.answer : '',
      chosenDescription: this.value.description,
      yesOrNo,
    };
  },
  methods: {
    triggerUpdate() {
      this.$emit('input', {
        answer: this.chosenAnswer,
        description: this.chosenDescription,
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.chosenAnswer = newVal.answer;
        this.chosenDescription = newVal.description;
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="row my-3">
      <div class="col-md-9">
        <div>
          <div v-if="titleNumberOrLetter">
            <div class="row">
              <div class="col-1">
                <h5>
                  <label>{{ titleNumberOrLetter }}</label>
                </h5>
              </div>
              <div class="col-11">
                <h5>
                  <label class="mandatory">{{ title }}</label>
                </h5>
              </div>
            </div>
          </div>
          <div v-else>
            <h5>
              <label class="mandatory">{{ title }}</label>
            </h5>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <validation-provider :name="validationFieldName" rules="required" v-slot="validationContext">
          <b-form-select
            :options="yesOrNo"
            :state="getValidationState(validationContext)"
            aria-describedby="select-error"
            class="form-control bg-white"
            :name="dropdownName"
            v-model="chosenAnswer"
            @change="triggerUpdate"
          >
            >
          </b-form-select>
          <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="select-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </div>
    </div>

    <div v-if="chosenAnswer" class="row my-3">
      <div class="col-md-12">
        <validation-provider :name="detailsName" rules="min:3|required" v-slot="validationContext" :skipIfEmpty="false">
          <div v-if="descriptionTitle">
            <h5>
              <label class="mandatory">{{ descriptionTitle }}</label>
            </h5>
          </div>
          <b-form-textarea
            :name="textAreaName"
            v-model="chosenDescription"
            @input="triggerUpdate"
            :id="dropdownName"
            rows="4"
            :state="getValidationState(validationContext)"
          ></b-form-textarea>
          <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </div>
    </div>
  </div>
</template>
