<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/enfranchisement/mutations';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchEnfranchisement from '@/api/buildings/patchEnfranchisement';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import enfranchisement from '@/functions/supportingDocuments/enfranchisement';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const optionsForCopyFields = {
  ETFL_ENCLOSED: 'Attached',
  ETFL_TO_FOLLOW: 'To follow',
};

const yesOrNoOrNotKnownOrNotRequired = {
  TFNKNR_YES: 'Yes',
  TFNKNR_NO: 'No',
  TFNKNR_NOT_KNOWN: 'Not Known',
  TFNKNR_NOT_REQUIRED: 'Not Required',
};

export default {
  name: 'Enfranchisement',
  components: { SimpleQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_ownedTwoYears: (state) => state.building.enfranchisement.ownedTwoYears,
      s_sellerPurchaseNotice: (state) => state.building.enfranchisement.sellerPurchaseNotice,
      s_sellerPurchaseNoticeCopy: (state) => state.building.enfranchisement.sellerPurchaseNoticeCopy,
      s_collectivePurchaseNotice: (state) => state.building.enfranchisement.collectivePurchaseNotice,
      s_collectivePurchaseNoticeCopy: (state) => state.building.enfranchisement.collectivePurchaseNoticeCopy,
      s_purchaseNoticeResponses: (state) => state.building.enfranchisement.purchaseNoticeResponses,
      s_purchaseNoticeResponsesCopy: (state) => state.building.enfranchisement.purchaseNoticeResponsesCopy,
      s_buildingId: (state) => state.building.buildingId,
    }),
    ownedTwoYears: {
      get() {
        return {
          answer: this.s_ownedTwoYears,
        };
      },
      set(value) {
        this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_OWNED_TWO_YEARS}`](value.answer);
      },
    },
    sellerPurchaseNotice: {
      get() {
        return {
          answer: this.s_sellerPurchaseNotice,
        };
      },
      set(value) {
        this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE}`](
          value.answer,
        );
      },
    },
    sellerPurchaseNoticeCopy: {
      get() {
        return {
          answer: this.s_sellerPurchaseNoticeCopy,
        };
      },
      set(value) {
        this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE_COPY}`](
          value.answer,
        );
      },
    },
    collectivePurchaseNotice: {
      get() {
        return {
          answer: this.s_collectivePurchaseNotice,
        };
      },
      set(value) {
        this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE}`](
          value.answer,
        );
      },
    },
    collectivePurchaseNoticeCopy: {
      get() {
        return {
          answer: this.s_collectivePurchaseNoticeCopy,
        };
      },
      set(value) {
        this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE_COPY}`](
          value.answer,
        );
      },
    },
    purchaseNoticeResponses: {
      get() {
        return {
          answer: this.s_purchaseNoticeResponses,
        };
      },
      set(value) {
        this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES}`](
          value.answer,
        );
      },
    },
    purchaseNoticeResponsesCopy: {
      get() {
        return {
          answer: this.s_purchaseNoticeResponsesCopy,
        };
      },
      set(value) {
        this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES_COPY}`](
          value.answer,
        );
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      yesOrNoOrNotKnownOrNotRequired,
      optionsForCopyFields,
      enfranchisement,
    };
  },
  watch: {
    sellerPurchaseNotice() {
      this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE_COPY}`](null);
    },
    collectivePurchaseNotice() {
      this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE_COPY}`](null);
    },
    purchaseNoticeResponses() {
      this[`${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES_COPY}`](null);
    },
  },
  methods: {
    ...mapMutations([
      `${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_OWNED_TWO_YEARS}`,
      `${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE}`,
      `${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE_COPY}`,
      `${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE}`,
      `${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE_COPY}`,
      `${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES}`,
      `${mutations.ENFRANCHISEMENT_PREFIX}${mutations.ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES_COPY}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchEnfranchisement(
        this.s_buildingId,
        this.s_ownedTwoYears,
        this.s_sellerPurchaseNotice,
        this.s_sellerPurchaseNoticeCopy,
        this.s_collectivePurchaseNotice,
        this.s_collectivePurchaseNoticeCopy,
        this.s_purchaseNoticeResponses,
        this.s_purchaseNoticeResponsesCopy,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-12">Enfranchisement</h2>

      <div class="col-12">
        <div class="section-note">
          <p>
            <b>Note:</b> ‘Enfranchisement’ is the right of a tenant to purchase the freehold from their landlord and the
            right of the tenant to extend the term of the lease.
          </p>
        </div>
      </div>

      <div class="border-top my-3"></div>
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <div class="col-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="ownedTwoYearsLabelName"
            title="Has the seller owned the property for at least two years?"
            v-model="ownedTwoYears"
            validationFieldName="owned two years"
          />
        </div>

        <div class="col-12 border-top">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnownOrNotRequired"
            labelName="sellerPurchaseNoticeLabelName"
            title="Has the seller served on the landlord a formal notice stating the seller’s wish to buy the freehold or be granted an extended lease?"
            v-model="sellerPurchaseNotice"
            validationFieldName="seller purchase notice"
          />
        </div>

        <div v-if="sellerPurchaseNotice.answer === 'TFNKNR_YES'">
          <div class="col-12">
            <simple-question
              :dropdownOptions="optionsForCopyFields"
              labelName="sellerPurchaseNoticeCopyLabelName"
              title="Please supply a copy."
              v-model="sellerPurchaseNoticeCopy"
              validationFieldName="seller purchase notice copy"
            />

            <inline-file-uploader
              v-if="sellerPurchaseNoticeCopy.answer === 'ETFL_ENCLOSED'"
              :requiredDocument="enfranchisement.sellerPurchaseNoticeCopy"
            />
          </div>
        </div>

        <div class="col-12 border-top">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnownOrNotRequired"
            labelName="collectivePurchaseNoticeLabelName"
            title="Is the seller aware of the service of any notice relating to the possible collective purchase of the freehold of the building or part of it by a group of tenants?"
            v-model="collectivePurchaseNotice"
            validationFieldName="collective purchase notice"
          />
        </div>

        <div v-if="collectivePurchaseNotice.answer === 'TFNKNR_YES'">
          <div class="col-12">
            <simple-question
              :dropdownOptions="optionsForCopyFields"
              labelName="collectivePurchaseNoticeCopyLabelName"
              title="Please supply a copy."
              v-model="collectivePurchaseNoticeCopy"
              validationFieldName="collective purchase notice copy"
            />

            <inline-file-uploader
              v-if="collectivePurchaseNoticeCopy.answer === 'ETFL_ENCLOSED'"
              :requiredDocument="enfranchisement.collectivePurchaseNoticeCopy"
            />
          </div>
        </div>

        <div class="col-12 border-top">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnownOrNotRequired"
            labelName="purchaseNoticeResponsesLabelName"
            title="Is the seller aware of any response to a notice disclosed?"
            v-model="purchaseNoticeResponses"
            validationFieldName="purchase notice responses"
          />
        </div>

        <div v-if="purchaseNoticeResponses.answer === 'TFNKNR_YES'">
          <div class="col-12">
            <simple-question
              :dropdownOptions="optionsForCopyFields"
              labelName="purchaseNoticeResponsesCopyLabelName"
              title="Please supply a copy."
              v-model="purchaseNoticeResponsesCopy"
              validationFieldName="purchase notice responses copy"
            />

            <inline-file-uploader
              v-if="purchaseNoticeResponsesCopy.answer === 'ETFL_ENCLOSED'"
              :requiredDocument="enfranchisement.purchaseNoticeResponsesCopy"
            />
          </div>
        </div>
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
