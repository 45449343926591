import axios from 'axios';

export default (
  id,
  ownedTwoYears,
  sellerPurchaseNotice,
  sellerPurchaseNoticeCopy,
  collectivePurchaseNotice,
  collectivePurchaseNoticeCopy,
  purchaseNoticeResponses,
  purchaseNoticeResponsesCopy,
) => {
  let preparedData = {
    owned_two_years: ownedTwoYears,
    seller_purchase_notice: sellerPurchaseNotice,
    collective_purchase_notice: collectivePurchaseNotice,
    purchase_notice_responses: purchaseNoticeResponses,
  };

  preparedData.seller_purchase_notice_copy = sellerPurchaseNotice ? sellerPurchaseNoticeCopy : null;
  preparedData.collective_purchase_notice_copy = collectivePurchaseNotice ? collectivePurchaseNoticeCopy : null;
  preparedData.purchase_notice_responses_copy = purchaseNoticeResponses ? purchaseNoticeResponsesCopy : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    enfranchisement: preparedData,
  });
};
