import axios from 'axios';

export default (
  id,
  alterationsSinceLeaseGranted,
  alterationsSinceLeaseGrantedDescription,
  consentObtained,
  consentObtainedDocument,
) => {
  let preparedData = {
    alterations_since_lease_granted: alterationsSinceLeaseGranted,
  };

  preparedData.alterations_since_lease_granted_description = alterationsSinceLeaseGranted
    ? alterationsSinceLeaseGrantedDescription
    : '';
  preparedData.consent_obtained = alterationsSinceLeaseGranted ? consentObtained : null;
  preparedData.consent_obtained_document =
    preparedData.consent_obtained === 'TFNKNR_YES' ? consentObtainedDocument : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, { leasehold_alteration: preparedData });
};
