import axios from 'axios';

export default (id, leaseTermsChange, leaseTermsChangeDocument, leaseTermsChangeDescription) => {
  const preparedData = {};

  preparedData.lease_terms_change = leaseTermsChange;
  preparedData.lease_terms_change_document = leaseTermsChange ? leaseTermsChangeDocument : null;
  preparedData.lease_terms_change_description =
    leaseTermsChange && leaseTermsChangeDocument == 'ETFLD_DETAILS' ? leaseTermsChangeDescription : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, { consent: preparedData });
};
