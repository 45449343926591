<script>
export default {
  name: 'FreeTextQuestion',
  props: {
    titleNumberOrLetter: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    validationFieldName: {
      type: String,
      required: true,
    },
    labelName: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: () => ({
        answer: null,
      }),
    },
    lockChange: {
      type: Boolean,
      default: () => false,
    },
    removeSectionSpacing: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    triggerUpdate() {
      this.$emit('input', this.value);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div :class="removeSectionSpacing ? 'row' : 'row my-3'">
    <div class="col-md-9">
      <div>
        <div v-if="titleNumberOrLetter">
          <div class="row">
            <div class="col-1">
              <h5>
                <label>{{ titleNumberOrLetter }}</label>
              </h5>
            </div>
            <div class="col-11">
              <h5>
                <label :class="required ? 'mandatory' : ''" :name="labelName">{{ title }}</label>
              </h5>
            </div>
          </div>
        </div>
        <div v-else>
          <h5>
            <label :class="required ? 'mandatory' : ''" :name="labelName">{{ title }}</label>
          </h5>
        </div>
      </div>
    </div>

    <div class="col-md-12 my-3">
      <validation-provider v-slot="validationContext" :name="validationFieldName" :rules="{ required }">
        <b-form-textarea
          ref="answer"
          v-model="value.answer"
          :disabled="lockChange"
          :name="validationFieldName"
          :state="getValidationState(validationContext)"
          aria-describedby="answer-type-error"
          class="form-control bg-white"
          rows="4"
          @change="triggerUpdate"
        ></b-form-textarea>
        <b-form-invalid-feedback data-error-name="answer-type-error"
          >{{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </div>
  </div>
</template>
