//import sortDocumentTypes from '@sort-group/sort-document-types';

import {
  SELLER_PURCHASE_NOTICE_COPY,
  COLLECTIVE_PURCHASE_NOTICE_COPY,
  PURCHASE_NOTICE_RESPONSES_COPY,
} from '@/values/fileSlugs';

export default {
  sellerPurchaseNoticeCopy: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: SELLER_PURCHASE_NOTICE_COPY, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'sellerPurchaseNoticeCopy',
      answers: ['ETFL_ENCLOSED', 'ETFL_TO_FOLLOW'],
      mainText:
        'Has the seller served on the landlord a formal notice stating the seller’s wish to buy the freehold or be granted an extended lease?',
      documentText: 'Please supply a seller purchase notice',
    },
  },
  collectivePurchaseNoticeCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: COLLECTIVE_PURCHASE_NOTICE_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'collectivePurchaseNoticeCopy',
      answers: ['ETFL_ENCLOSED', 'ETFL_TO_FOLLOW'],
      mainText:
        'Is the seller aware of the service of any notice relating to the possible collective purchase of the freehold of the building or part of it by a group of tenants?',
      documentText: 'Please supply a collective purchase notice',
    },
  },
  purchaseNoticeResponsesCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: PURCHASE_NOTICE_RESPONSES_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'purchaseNoticeResponsesCopy',
      answers: ['ETFL_ENCLOSED', 'ETFL_TO_FOLLOW'],
      mainText: 'Is the seller aware of any response to a notice disclosed?',
      documentText: 'Please supply purchase notice responses copy',
    },
  },
};
