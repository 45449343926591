<script>
import Modal from '@/components/Modal';
import UploadedFilesList from '@/components/documentManager/UploadedFilesList';
import sortDocumentTypes from '@sort-group/sort-document-types';
import filterDocuments from '@/functions/document/filterDocuments';
import { mapState } from 'vuex';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';

export default {
  name: 'InlineFileUploader',
  components: { Modal, UploadedFilesList },
  computed: {
    ...mapState({
      entityId: (state) => {
        if (state.sale.saleId !== null) {
          return state.sale.saleId;
        } else if (state.purchase.purchaseId !== null) {
          return state.purchase.purchaseId;
        } else if (state.remortgage.remortgageId !== null) {
          return state.remortgage.remortgageId;
        } else {
          return null;
        }
      },
      entityType: (state) => {
        if (state.sale.saleId !== null) {
          return 'sale';
        } else if (state.purchase.purchaseId !== null) {
          return 'purchase';
        } else if (state.remortgage.remortgageId !== null) {
          return 'remortgage';
        } else {
          return null;
        }
      },
      entity: (state) => {
        if (state.sale.saleId !== null) {
          return state.sale;
        } else if (state.purchase.purchaseId !== null) {
          return state.purchase;
        } else if (state.remortgage.remortgageId !== null) {
          return state.remortgage;
        } else {
          return null;
        }
      },
      documents: (state) => state.documents.documents,
    }),
    alreadyUploadedFilesQty() {
      return Object.keys(this.uploadedDocuments).length;
    },
    listOfRelevantDocs() {
      if (this.uploadedDocuments.length !== 0) {
        return pickBy(this.sortDocumentTypes, (value, key) => {
          return map(this.uploadedDocuments, 'documentType').includes(key);
        });
      } else {
        return this.requiredDocument.documentTypes;
      }
    },
    showAcceptedDocumentList() {
      return this.requiredDocument.showAcceptedDocumentList;
    },
  },
  props: {
    requiredDocument: {
      type: Object,
      default: () => ({}),
      true: true,
    },
  },
  data() {
    return {
      showModal: false,
      uploadedDocuments: {},
      sortDocumentTypes,
    };
  },
  methods: {
    toggleShowModal() {
      this.showModal = !this.showModal;
    },
  },
  mounted() {
    this.uploadedDocuments = filterDocuments(this.documents, this.requiredDocument);
  },
  watch: {
    documents() {
      this.uploadedDocuments = filterDocuments(this.documents, this.requiredDocument);
    },
  },
};
</script>

<template>
  <div class="row my-3">
    <div class="col-md-9">
      <h5 v-if="alreadyUploadedFilesQty !== 0">
        <label>You already uploaded required document:</label>
      </h5>
      <h5 v-else>
        <label>Please provide required document(s):</label>
      </h5>
      <ul v-if="alreadyUploadedFilesQty !== 0 || showAcceptedDocumentList">
        <li v-for="(docTypeText, index) in listOfRelevantDocs" :key="index" class="text-capitalize">
          {{ docTypeText }}
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <div v-if="alreadyUploadedFilesQty !== 0">
        <button type="button" class="btn btn-success btn-sm" @click="toggleShowModal">
          View files ({{ this.alreadyUploadedFilesQty }} uploaded)
        </button>
      </div>
      <div v-else>
        <button type="button" class="btn btn-info btn-sm" @click="toggleShowModal">
          Upload files ({{ this.alreadyUploadedFilesQty }} uploaded)
        </button>
      </div>
    </div>
    <modal
      v-if="this.showModal"
      :show-buttons="true"
      closeButtonText="Close"
      :fullModalWidth="true"
      @proceed="toggleShowModal"
    >
      <uploaded-files-list
        v-if="this.showModal"
        :entityId="entityId"
        :entityType="entityType"
        :entity="entity"
        :requiredDocument="requiredDocument"
      />
    </modal>
  </div>
</template>
