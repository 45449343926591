import axios from 'axios';

export default (
  id,
  remediationWorks,
  remediationWorksCopy,
  qualifyingLease,
  deedOfCertificate,
  deedOfCertificateCompletion,
  deedOfCertificateCopy,
  notifiedOfSellIntention,
  landlordsCertificateReceived,
  landlordsCertificateCopy,
) => {
  console.log('Value: ', landlordsCertificateReceived);
  let preparedData = {
    remediation_works: remediationWorks,
    qualifying_lease: qualifyingLease,
    deed_of_certificate: deedOfCertificate,
    notified_of_sell_intention: notifiedOfSellIntention,
    landlords_certificate_received: landlordsCertificateReceived,
  };

  preparedData.remediation_works_copy = remediationWorks === 'YNNA_YES' ? remediationWorksCopy : null;
  preparedData.deed_of_certificate_completion = deedOfCertificate === 'YNNA_YES' ? deedOfCertificateCompletion : null;
  preparedData.deed_of_certificate_copy = deedOfCertificate === 'YNNA_YES' ? deedOfCertificateCopy : null;
  preparedData.landlords_certificate_copy = landlordsCertificateReceived ? landlordsCertificateCopy : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    leasehold_safety: preparedData,
  });
};
