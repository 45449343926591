import axios from 'axios';

export default (id, saleNotice, saleNoticeCopy, generalNotice, generalNoticeCopy) => {
  const preparedData = {};

  preparedData.sale_notice = saleNotice;
  preparedData.sale_notice_copy = saleNotice ? saleNoticeCopy : null;

  preparedData.general_notice = generalNotice;
  preparedData.general_notice_copy = generalNotice ? generalNoticeCopy : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, { leasehold_notice: preparedData });
};
