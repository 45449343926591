<script>
import { mapMutations, mapState } from 'vuex';
import patchOwnership from '@/api/buildings/patchOwnership';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import FreeTextQuestion from '@/components/forms/inputGroups/FreeTextQuestion';
import {
  OWNERSHIP_PREFIX,
  OWNERSHIP_SET_FREEHOLD_CONTROLLED_BY_TENANTS,
  OWNERSHIP_SET_FREEHOLD_NOT_CONTROLLED_BY_TENANTS,
  OWNERSHIP_SET_HEAD_LEASEHOLDER_CONTROLLED_BY_TENANTS,
  OWNERSHIP_SET_HEAD_LEASEHOLDER_PRESENT,
  OWNERSHIP_SET_MANAGEMENT_COMPANY_DISSOLVED,
  OWNERSHIP_SET_MANAGING_AGENT_FOR_RENT_OR_MANAGEMENT,
  OWNERSHIP_SET_MANAGING_BUILDING_COMPANY_RESPONSIBLE,
  OWNERSHIP_SET_MANAGING_BUILDING_FREEHOLDER_RESPONSIBLE,
  OWNERSHIP_SET_MANAGING_BUILDING_LEASEHOLDER_RESPONSIBLE,
  OWNERSHIP_SET_MANAGING_BUILDING_OTHER_RESPONSIBLE,
  OWNERSHIP_SET_MANAGING_BUILDING_RIGHT_TO_MANAGE_COMPANY_RESPONSIBLE,
} from '@/store/ownership/mutations';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

export default {
  name: 'OwnershipAndOwnership',
  components: { FreeTextQuestion, SimpleQuestion },
  computed: {
    ...mapState({
      s_buildingId: (state) => state.building.buildingId,
      s_freeholdControlledByTenants: (state) => state.building.ownership.freeholdControlledByTenants,
      s_freeholdNotControlledByTenants: (state) => state.building.ownership.freeholdNotControlledByTenants,
      s_headLeaseholderPresent: (state) => state.building.ownership.headLeaseholderPresent,
      s_headLeaseholderControlledByTenants: (state) => state.building.ownership.headLeaseholderControlledByTenants,
      s_managingBuildingFreeholderResponsible: (state) =>
        state.building.ownership.managingBuildingFreeholderResponsible,
      s_managingBuildingLeaseholderResponsible: (state) =>
        state.building.ownership.managingBuildingLeaseholderResponsible,
      s_managingBuildingCompanyResponsible: (state) => state.building.ownership.managingBuildingCompanyResponsible,
      s_managingBuildingRightToManageCompanyResponsible: (state) =>
        state.building.ownership.managingBuildingRightToManageCompanyResponsible,
      s_managingBuildingOtherResponsible: (state) => state.building.ownership.managingBuildingOtherResponsible,
      s_managementCompanyDissolved: (state) => state.building.ownership.managementCompanyDissolved,
      s_managingAgentForRentOrManagement: (state) => state.building.ownership.managingAgentForRentOrManagement,
    }),
    freeholdControlledByTenants: {
      get() {
        return {
          answer: this.s_freeholdControlledByTenants,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_FREEHOLD_CONTROLLED_BY_TENANTS}`](value.answer);
      },
    },
    freeholdNotControlledByTenants: {
      get() {
        return {
          answer: this.s_freeholdNotControlledByTenants,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_FREEHOLD_NOT_CONTROLLED_BY_TENANTS}`](value.answer);
      },
    },
    headLeaseholderPresent: {
      get() {
        return {
          answer: this.s_headLeaseholderPresent,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_HEAD_LEASEHOLDER_PRESENT}`](value.answer);
      },
    },
    headLeaseholderControlledByTenants: {
      get() {
        return {
          answer: this.s_headLeaseholderControlledByTenants,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_HEAD_LEASEHOLDER_CONTROLLED_BY_TENANTS}`](value.answer);
      },
    },
    managingBuildingFreeholderResponsible: {
      get() {
        return {
          answer: this.s_managingBuildingFreeholderResponsible,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_FREEHOLDER_RESPONSIBLE}`](value.answer);
      },
    },
    managingBuildingLeaseholderResponsible: {
      get() {
        return {
          answer: this.s_managingBuildingLeaseholderResponsible,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_LEASEHOLDER_RESPONSIBLE}`](value.answer);
      },
    },
    managingBuildingCompanyResponsible: {
      get() {
        return {
          answer: this.s_managingBuildingCompanyResponsible,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_COMPANY_RESPONSIBLE}`](value.answer);
      },
    },
    managingBuildingRightToManageCompanyResponsible: {
      get() {
        return {
          answer: this.s_managingBuildingRightToManageCompanyResponsible,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_RIGHT_TO_MANAGE_COMPANY_RESPONSIBLE}`](value.answer);
      },
    },
    managingBuildingOtherResponsible: {
      get() {
        return {
          answer: this.s_managingBuildingOtherResponsible,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_OTHER_RESPONSIBLE}`](value.answer);
      },
    },
    managementCompanyDissolved: {
      get() {
        return {
          answer: this.s_managementCompanyDissolved,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGEMENT_COMPANY_DISSOLVED}`](value.answer);
      },
    },
    managingAgentForRentOrManagement: {
      get() {
        return {
          answer: this.s_managingAgentForRentOrManagement,
        };
      },
      set(value) {
        this[`${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_AGENT_FOR_RENT_OR_MANAGEMENT}`](value.answer);
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
    };
  },
  methods: {
    ...mapMutations([
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_FREEHOLD_CONTROLLED_BY_TENANTS}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_FREEHOLD_NOT_CONTROLLED_BY_TENANTS}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_HEAD_LEASEHOLDER_PRESENT}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_HEAD_LEASEHOLDER_CONTROLLED_BY_TENANTS}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_FREEHOLDER_RESPONSIBLE}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_LEASEHOLDER_RESPONSIBLE}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_COMPANY_RESPONSIBLE}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_RIGHT_TO_MANAGE_COMPANY_RESPONSIBLE}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_BUILDING_OTHER_RESPONSIBLE}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGEMENT_COMPANY_DISSOLVED}`,
      `${OWNERSHIP_PREFIX}${OWNERSHIP_SET_MANAGING_AGENT_FOR_RENT_OR_MANAGEMENT}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      await patchOwnership(
        this.s_buildingId,
        this.s_freeholdControlledByTenants,
        this.s_freeholdNotControlledByTenants,
        this.s_headLeaseholderPresent,
        this.s_headLeaseholderControlledByTenants,
        this.s_managingBuildingFreeholderResponsible,
        this.s_managingBuildingLeaseholderResponsible,
        this.s_managingBuildingCompanyResponsible,
        this.s_managingBuildingRightToManageCompanyResponsible,
        this.s_managingBuildingOtherResponsible,
        this.s_managementCompanyDissolved,
        this.s_managingAgentForRentOrManagement,
      );
      this.saving = false;

      return true;
    },
  },
};
</script>

<template>
  <!-- Set page validation wrapper -->
  <ValidationObserver ref="observer">
    <div class="row">
      <div class="col">
        <h2 class="col-md-12">Ownership</h2>

        <div class="col-md-12 mt-3">
          <h5><label>Who owns the freehold?</label></h5>
        </div>
        <div class="col-md-12">
          <simple-question
            v-model="freeholdNotControlledByTenants"
            :dropdownOptions="yesOrNo"
            labelName="freeholdCompanyNotOwnedByTenants"
            title="A person or company that is not controlled by the tenants"
            titleNumberOrLetter="(a)"
            validationFieldName="not controlled by the tenants"
          ></simple-question>
        </div>
        <div class="col-md-12">
          <simple-question
            v-model="freeholdControlledByTenants"
            :dropdownOptions="yesOrNo"
            labelName="freeholdCompanyOwnedByTenants"
            title="A person or company that the tenants control"
            titleNumberOrLetter="(b)"
            validationFieldName="controlled by the tenants"
          ></simple-question>
        </div>
        <div class="border-top my-3"></div>

        <div class="col-md-12">
          <simple-question
            v-model="headLeaseholderPresent"
            :dropdownOptions="yesOrNo"
            labelName="ownershipDeregisteredLabelName"
            title="Is there a headlease?"
            titleNumberOrLetter
            validationFieldName="head leaseholder present"
          ></simple-question>
        </div>
        <div v-if="headLeaseholderPresent.answer" class="col-md-12">
          <simple-question
            v-model="headLeaseholderControlledByTenants"
            :dropdownOptions="yesOrNo"
            labelName="headLeaseholderControlledByTenants"
            title="Is the head leaseholder a person or company that is controlled by the tenants?"
            validationFieldName="head leaseholder controlled by tenants"
          ></simple-question>
        </div>

        <div class="border-top my-3"></div>

        <div class="col-md-12 mt-3">
          <h5><label>Who is responsible for managing the building?</label></h5>
        </div>
        <div class="col-md-12">
          <simple-question
            v-model="managingBuildingFreeholderResponsible"
            :dropdownOptions="yesOrNo"
            labelName="freeholdCompanyNotOwnedByTenants"
            title="The freeholder"
            titleNumberOrLetter="(a)"
            validationFieldName="freeholder responsible for building management"
          ></simple-question>
        </div>
        <div class="col-md-12">
          <simple-question
            v-model="managingBuildingLeaseholderResponsible"
            :dropdownOptions="yesOrNo"
            labelName="freeholdCompanyNotOwnedByTenants"
            title="The head leaseholder"
            titleNumberOrLetter="(b)"
            validationFieldName="leaseholder responsible for building management"
          ></simple-question>
        </div>
        <div class="col-md-12">
          <simple-question
            v-model="managingBuildingCompanyResponsible"
            :dropdownOptions="yesOrNo"
            labelName="freeholdCompanyNotOwnedByTenants"
            title="A management company named in the lease of the property"
            titleNumberOrLetter="(c)"
            validationFieldName="managing company responsible for building management"
          ></simple-question>
        </div>
        <div class="col-md-12">
          <simple-question
            v-model="managingBuildingRightToManageCompanyResponsible"
            :dropdownOptions="yesOrNo"
            labelName="freeholdCompanyNotOwnedByTenants"
            title="A Right to Manage company set up by the tenants under statutory rights"
            titleNumberOrLetter="(d)"
            validationFieldName="Right to Manage company responsible for building management"
          ></simple-question>
        </div>
        <div class="col-md-12">
          <free-text-question
            v-model="managingBuildingOtherResponsible"
            :dropdownOptions="yesOrNo"
            :required="false"
            labelName="freeholdCompanyNotOwnedByTenants"
            title="Other (please specify)"
            titleNumberOrLetter="(e)"
            validationFieldName="other responsible for building management"
          ></free-text-question>
        </div>

        <div class="border-top my-3"></div>

        <div class="col-md-12">
          <simple-question
            v-model="managementCompanyDissolved"
            :dropdownOptions="yesOrNo"
            labelName="freeholdCompanyNotOwnedByTenants"
            title="Has any tenants’ management company been dissolved or struck off the register at Companies House?"
            validationFieldName="management company dissolved"
          ></simple-question>
        </div>

        <div class="col-md-12">
          <simple-question
            v-model="managingAgentForRentOrManagement"
            :dropdownOptions="yesOrNo"
            labelName="managingAgentEmployedForRentOrManagement"
            title="Does the landlord, tenants’ management company or Right to Manage company employ a managing agent to collect rent or manage the building?"
            validationFieldName="managing agent to collect rent"
          ></simple-question>
        </div>

        <div class="border-top my-3"></div>
      </div>
    </div>
  </ValidationObserver>
  <!-- /set page validation wrapper -->
</template>
