import axios from 'axios';

export default (
  id,
  buildingInsurance,
  externalDecorated,
  internalDecorated,
  sellerMaintenanceContribution,
  decorationExpenses,
  decorationExpensesDescription,
  managementProblems,
  managementProblemsDescription,
  buildingSafetyRiskExistence,
  buildingSafetyRiskExistenceDescription,
  serviceChargeChallenge,
  serviceChargeChallengeDescription,
  serviceChargeCollectionDifficulties,
  serviceChargeCollectionDifficultiesDescription,
  serviceChargeOwing,
  serviceChargeOwingDescription,
) => {
  let preparedData = {
    building_insurance: buildingInsurance,
    external_decorated: externalDecorated,
    internal_decorated: internalDecorated,
    seller_maintenance_contribution: sellerMaintenanceContribution,
  };

  preparedData.decoration_expenses = sellerMaintenanceContribution ? decorationExpenses : null;
  preparedData.decoration_expenses_description =
    sellerMaintenanceContribution && decorationExpenses ? decorationExpensesDescription : '';
  preparedData.management_problems = sellerMaintenanceContribution ? managementProblems : null;
  preparedData.management_problems_description =
    sellerMaintenanceContribution && managementProblems ? managementProblemsDescription : '';
  preparedData.building_safety_risk_existence = sellerMaintenanceContribution ? buildingSafetyRiskExistence : null;
  preparedData.building_safety_risk_existence_description =
    sellerMaintenanceContribution && buildingSafetyRiskExistence ? buildingSafetyRiskExistenceDescription : '';
  preparedData.service_charge_challenge = sellerMaintenanceContribution ? serviceChargeChallenge : null;
  preparedData.service_charge_challenge_description =
    sellerMaintenanceContribution && serviceChargeChallenge ? serviceChargeChallengeDescription : '';
  preparedData.service_charge_collection_difficulties = sellerMaintenanceContribution
    ? serviceChargeCollectionDifficulties
    : null;
  preparedData.service_charge_collection_difficulties_description =
    sellerMaintenanceContribution && serviceChargeCollectionDifficulties
      ? serviceChargeCollectionDifficultiesDescription
      : '';
  preparedData.service_charge_owing = sellerMaintenanceContribution ? serviceChargeOwing : null;
  preparedData.service_charge_owing_description =
    sellerMaintenanceContribution && serviceChargeOwing ? serviceChargeOwingDescription : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, { maintenance: preparedData });
};
