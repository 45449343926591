<script>
import { mapMutations, mapState } from 'vuex';
import * as leaseholdInformationMutations from '@/store/leaseholdInformation/mutations';
import patchLeaseholdInformation from '@/api/buildings/patchLeaseholdInformation';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';

const propertyOptions = {
  LI_FLAT: 'Flat',
  LI_LONG_LEASEHOLD_HOUSE: 'House',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

export default {
  components: { SimpleQuestion },
  comments: [SimpleQuestion],
  name: 'LeaseholdInformation',
  computed: {
    ...mapState({
      s_leaseholdType: (state) => state.building.leaseholdInformation.leaseholdType,
      s_sharedOwnership: (state) => state.building.leaseholdInformation.sharedOwnership,
      s_sellerPaysRent: (state) => state.building.leaseholdInformation.sellerPaysRent,
      s_yearlyRent: (state) => state.building.leaseholdInformation.yearlyRent,
      s_rentPaymentFrequency: (state) => state.building.leaseholdInformation.rentPaymentFrequency,
      s_rentSubjectToIncrease: (state) => state.building.leaseholdInformation.rentSubjectToIncrease,
      s_rentRevisionFrequency: (state) => state.building.leaseholdInformation.rentRevisionFrequency,
      s_increaseCalculationDescription: (state) => state.building.leaseholdInformation.increaseCalculationDescription,
      s_buildingId: (state) => state.building.buildingId,
    }),
    leaseholdType: {
      get() {
        return {
          answer: this.s_leaseholdType,
        };
      },
      set(value) {
        this[
          `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_LEASEHOLD_TYPE}`
        ](value.answer);
      },
    },
    sharedOwnership: {
      get() {
        return {
          answer: this.s_sharedOwnership,
        };
      },
      set(value) {
        this[
          `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_SHARED_OWNERSHIP}`
        ](value.answer);
      },
    },
    sellerPaysRent: {
      get() {
        return {
          answer: this.s_sellerPaysRent,
        };
      },
      set(value) {
        this[
          `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_SELLER_PAYS_RENT}`
        ](value.answer);
      },
    },
    yearlyRent: {
      get() {
        return this.s_yearlyRent;
      },
      set(value) {
        this[
          `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_YEARLY_RENT}`
        ](value);
      },
    },
    rentPaymentFrequency: {
      get() {
        return this.s_rentPaymentFrequency;
      },
      set(value) {
        this[
          `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_RENT_PAYMENT_FREQUENCY}`
        ](value);
      },
    },
    rentSubjectToIncrease: {
      get() {
        return {
          answer: this.s_rentSubjectToIncrease,
        };
      },
      set(value) {
        this[
          `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_RENT_SUBJECT_TO_INCREASE}`
        ](value.answer);
      },
    },
    rentRevisionFrequency: {
      get() {
        return this.s_rentRevisionFrequency;
      },
      set(value) {
        this[
          `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_RENT_REVISION_FREQUENCY}`
        ](value);
      },
    },
    increaseCalculationDescription: {
      get() {
        return this.s_increaseCalculationDescription;
      },
      set(value) {
        this[
          `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_INCREASE_CALCULATION_DESCRIPTION}`
        ](value);
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      propertyOptions,
    };
  },
  methods: {
    ...mapMutations([
      `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_LEASEHOLD_TYPE}`,
      `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_SHARED_OWNERSHIP}`,
      `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_SELLER_PAYS_RENT}`,
      `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_YEARLY_RENT}`,
      `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_RENT_PAYMENT_FREQUENCY}`,
      `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_RENT_SUBJECT_TO_INCREASE}`,
      `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_RENT_REVISION_FREQUENCY}`,
      `${leaseholdInformationMutations.LEASEHOLD_INFORMATION_PREFIX}${leaseholdInformationMutations.LEASEHOLD_INFORMATION_SET_INCREASE_CALCULATION_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      await patchLeaseholdInformation(
        this.s_buildingId,
        this.s_leaseholdType,
        this.s_sharedOwnership,
        this.s_sellerPaysRent,
        this.s_yearlyRent,
        this.s_rentPaymentFrequency,
        this.s_rentSubjectToIncrease,
        this.s_rentRevisionFrequency,
        this.s_increaseCalculationDescription,
      );
      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <!-- Set page validation wrapper -->
  <ValidationObserver ref="observer">
    <div class="row">
      <div class="col">
        <h2 class="col-md-12">Leasehold information</h2>

        <!-- Question 1 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="propertyOptions"
            labelName="leaseholdTypeLabelName"
            title="What type of leasehold property does the seller own? (‘Flat’ includes maisonette and apartment)."
            v-model="leaseholdType"
            validationFieldName="leasehold type"
          ></simple-question>
        </div>

        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="sharedOwnershipLabelName"
            title="Is it shared ownership property?"
            v-model="sharedOwnership"
            validationFieldName="shared ownership"
          ></simple-question>
        </div>
        <!-- question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="sellerPaysRentLabelName"
            title="Does the seller pay rent for the property? If Yes:"
            v-model="sellerPaysRent"
            validationFieldName="seller pays rent"
          ></simple-question>
        </div>

        <div class="col-md-12" v-if="sellerPaysRent.answer">
          <div class="row my-3">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-1">
                  <h5><label>(a)</label></h5>
                </div>
                <div class="col-md-11">
                  <h5><label class="mandatory">How much rent is due each year?</label></h5>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <ValidationProvider name="yearly rent" rules="required|numeric|min_value:1" v-slot="{ errors }">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">£</span>
                  </div>
                  <input type="number" class="form-control" aria-label="yearly rent" v-model="yearlyRent" />
                </div>
                <span class="form-text text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-1">
                  <h5><label>(b)</label></h5>
                </div>
                <div class="col-md-11">
                  <h5><label class="mandatory">How regularly is the rent paid? (e.g. annually)</label></h5>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <ValidationProvider name="payment regularity" rules="required|min:3" v-slot="{ errors }">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"></span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="payment regularity"
                    v-model="rentPaymentFrequency"
                  />
                </div>
                <span class="form-text text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <simple-question
            v-model="rentSubjectToIncrease"
            :dropdownOptions="yesOrNo"
            labelName="rentSubjectToIncreaseLabelName"
            titleNumberOrLetter="(c)"
            title="Is the rent subject to increase?"
            validationFieldName="rent subject to increase"
          ></simple-question>

          <div v-if="rentSubjectToIncrease.answer">
            <h5>If Yes:</h5>

            <div class="row my-3">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-1">
                    <h5><label>(d)</label></h5>
                  </div>
                  <div class="col-md-11">
                    <h5><label class="mandatory">How often is the rent reviewed?</label></h5>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <ValidationProvider name="the rent reviewed" rules="required|min:3" v-slot="{ errors }">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="the rent reviewed"
                      v-model="rentRevisionFrequency"
                    />
                  </div>
                  <span class="form-text text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-1">
                    <h5><label>(e)</label></h5>
                  </div>
                  <div class="col-md-11">
                    <h5>
                      <label class="mandatory">
                        How is the increase calculated? (eg set figure, doubling, in line with Retail Price Index,
                        Consumer Price Index, etc)
                      </label>
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <validation-provider name="increase calculated" rules="required|min:3" v-slot="validationContext">
                  <b-form-textarea
                    v-model="increaseCalculationDescription"
                    id="increaseCalculationDescription"
                    rows="4"
                    :state="getValidationState(validationContext)"
                  ></b-form-textarea>
                  <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
  <!-- /set page validation wrapper -->
</template>
