<script>
import { mapMutations, mapState } from 'vuex';
import * as complaintsMutations from '@/store/complaints/mutations';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import patchComplaints from '@/api/buildings/patchComplaints';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

export default {
  name: 'Complaints',
  components: {
    OpenQuestion,
  },
  computed: {
    ...mapState({
      s_landlordComplaint: (state) => state.building.complaints.landlordComplaint,
      s_landlordComplaintDescription: (state) => state.building.complaints.landlordComplaintDescription,
      s_sellerComplaint: (state) => state.building.complaints.sellerComplaint,
      s_sellerComplaintDescription: (state) => state.building.complaints.sellerComplaintDescription,
      s_buildingId: (state) => state.building.buildingId,
    }),
    landlordComplaint: {
      get() {
        return {
          answer: this.s_landlordComplaint,
          description: this.s_landlordComplaintDescription,
        };
      },
      set(value) {
        this[`${complaintsMutations.COMPLAINTS_PREFIX}${complaintsMutations.COMPLAINTS_SET_LANDLORD}`](value.answer);
        this[`${complaintsMutations.COMPLAINTS_PREFIX}${complaintsMutations.COMPLAINTS_SET_LANDLORD_DESCRIPTION}`](
          value.description,
        );
      },
    },
    sellerComplaint: {
      get() {
        return {
          answer: this.s_sellerComplaint,
          description: this.s_sellerComplaintDescription,
        };
      },
      set(value) {
        this[`${complaintsMutations.COMPLAINTS_PREFIX}${complaintsMutations.COMPLAINTS_SET_SELLER}`](value.answer);
        this[`${complaintsMutations.COMPLAINTS_PREFIX}${complaintsMutations.COMPLAINTS_SET_SELLER_DESCRIPTION}`](
          value.description,
        );
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
    };
  },
  methods: {
    ...mapMutations([
      `${complaintsMutations.COMPLAINTS_PREFIX}${complaintsMutations.COMPLAINTS_SET_LANDLORD}`,
      `${complaintsMutations.COMPLAINTS_PREFIX}${complaintsMutations.COMPLAINTS_SET_LANDLORD_DESCRIPTION}`,
      `${complaintsMutations.COMPLAINTS_PREFIX}${complaintsMutations.COMPLAINTS_SET_SELLER}`,
      `${complaintsMutations.COMPLAINTS_PREFIX}${complaintsMutations.COMPLAINTS_SET_SELLER_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchComplaints(
        this.s_buildingId,
        this.s_landlordComplaint,
        this.s_landlordComplaintDescription,
        this.s_sellerComplaint,
        this.s_sellerComplaintDescription,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <h2 class="col-md-12">Complaints</h2>

        <!-- Question 1 -->
        <div class="col-md-12">
          <open-question
            :dropdownOptions="yesOrNo"
            dropdownName="landlordComplaintDropdownName"
            labelName="landlordComplaintLabelName"
            textAreaName="landlordComplaintDescriptionTextAreaName"
            detailsName="landlord complaint details"
            title="Has the seller received any complaint from the landlord, the management company or any neighbour about anything the seller has or has not done? If Yes, please give details:"
            v-model="landlordComplaint"
            validationFieldName="landlord complaint"
          />
        </div>
        <!-- \question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <open-question
            :dropdownOptions="yesOrNo"
            dropdownName="sellerComplaintDropdownName"
            labelName="sellerComplaintLabelName"
            textAreaName="sellerComplaintTextAreaName"
            detailsName="seller complaint details"
            title="Has the seller complained or had cause to complain to or about the landlord, the management company, or any neighbour? If Yes, please give details:"
            v-model="sellerComplaint"
            validationFieldName="seller complaint"
          />
        </div>
        <!-- \question 2 end -->
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
