<script>
import DatePicker from '@/components/DatePicker';

export default {
  components: { DatePicker },
  name: 'FreeTypeYearQuestion',
  data() {
    return {
      date: this.value !== null ? this.value.date : null,
      disabled: false,
    };
  },
  props: {
    titleNumberOrLetter: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    labelName: {
      type: String,
      required: true,
    },
    validationFieldName: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: false,
      default: () => ({
        date: false,
        not_known: false,
      }),
    },
    notKnown: {
      type: Boolean,
      required: false,
    },
    minimumView: {
      type: String,
      default: () => 'year',
    },
    labelText: {
      type: String,
      default: () => 'Year',
      required: false,
    },
  },
  methods: {
    toggleSelected() {
      this.disabled = !this.disabled;

      if (this.disabled) {
        this.$emit('input', { not_known: true });
      } else {
        this.triggerUpdate(this.date);
      }
    },
    triggerUpdate(date) {
      this.$emit('input', {
        date: date,
      });
    },
  },
  computed: {
    requireDate: function () {
      // Switch off required rule when filed is disabled
      return !this.disabled ? this.required : false;
    },
  },
  mounted() {
    if (this.value !== null && typeof this.value.not_known !== 'undefined') {
      this.disabled = this.value.not_known;
    }
  },
};
</script>

<template>
  <div class="row my-3">
    <div class="col-md-9 pt-2">
      <div v-if="title">
        <div v-if="titleNumberOrLetter">
          <div class="row">
            <div class="col-md-1">
              <h5>
                <label>{{ titleNumberOrLetter }}</label>
              </h5>
            </div>
            <div class="col-md-11">
              <h5>
                <label :name="labelName" :class="requireDate ? 'mandatory' : ''">{{ title }}</label>
              </h5>
            </div>
          </div>
        </div>
        <div v-else>
          <h5>
            <label :name="labelName" :class="requireDate ? 'mandatory' : ''">{{ title }}</label>
          </h5>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="row">
        <label
          :class="
            requireDate
              ? 'col-md-3 col-form-label align-self-center mandatory'
              : 'col-md-3 col-form-label align-self-center'
          "
          :for="labelName"
          >{{ labelText }}</label
        >
        <date-picker
          :id="labelName"
          :required="requireDate"
          :validationFieldName="validationFieldName"
          class="col-md-9 ml-0"
          v-model="date"
          :minimumView="minimumView"
          :disabled="disabled"
          @input="triggerUpdate"
        ></date-picker>
      </div>
      <div class="row no-gutters">
        <div class="form-group form-check my-1" v-show="notKnown">
          <input
            type="checkbox"
            :id="`${labelName}NotKnown`"
            class="form-check-input"
            :checked="disabled"
            @change="toggleSelected"
          />
          <label class="form-check-label" :for="`${labelName}NotKnown`">Not Known</label>
        </div>
      </div>
    </div>
  </div>
</template>
