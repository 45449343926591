import axios from 'axios';

export default (
  id,
  leaseholdType,
  sharedOwnership,
  sellerPaysRent,
  yearlyRent,
  rentPaymentFrequency,
  rentSubjectToIncrease,
  rentRevisionFrequency,
  increaseCalculationDescription,
) => {
  let preparedData = {
    leasehold_type: leaseholdType,
    shared_ownership: sharedOwnership,
    seller_pays_rent: sellerPaysRent,
  };

  preparedData.yearly_rent = sellerPaysRent ? Number(yearlyRent) : null;
  preparedData.rent_payment_frequency = sellerPaysRent ? rentPaymentFrequency : '';
  preparedData.rent_subject_to_increase = sellerPaysRent ? rentSubjectToIncrease : null;
  preparedData.rent_revision_frequency = sellerPaysRent && rentSubjectToIncrease ? rentRevisionFrequency : null;
  preparedData.increase_calculation_description =
    sellerPaysRent && rentSubjectToIncrease ? increaseCalculationDescription : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, { leasehold_information: preparedData });
};
