<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex';
import FormWrapper from '@/components/FormWrapper';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import LeaseholdInformation from '@/components/forms/LeaseholdInformation';
import Complaints from '@/components/forms/Complaints';
import OwnershipAndManagement from '@/components/forms/ta7/OwnershipAndManagement';
import RelevantDocuments from '@/components/forms/RelevantDocuments';
import LeaseholdNotices from '@/components/forms/LeaseholdNotices';
import LeaseholdAlterations from '@/components/forms/LeaseholdAlterations';
import LeaseholdSafety from '@/components/forms/LeaseholdSafety';
import Contact from '@/components/forms/Contact';
import Consents from '@/components/forms/Consents';
import Enfranchisement from '@/components/forms/Enfranchisement';
import Maintenance from '@/components/forms/Maintenance';
import PdfPreview from '@/components/forms/SalePDFpreview';
import AboutTA7 from '@/components/forms/AboutPages/AboutTA7';
import PropertyAndClientDetails from '@/components/forms/PropertyAndClientDetails';
import PostTrackingEvent from '@/api/trackingEvents/postTrackingEvents';
import Modal from '@/components/Modal';
import patchTa7Submitted from '@/api/sales/patchTa7Submitted';
import SummaryPage from '@/components/forms/SummaryPage';
import FormsChangelogComponent from '@/components/forms/FormsChangelogComponent';
import FormCompletion from '@/mixins/FormCompletion';

export default {
  name: 'TA7',
  components: {
    PropertyAndClientDetails,
    FormWrapper,
    FormWizard,
    TabContent,
    WizardButton,
    LeaseholdInformation,
    OwnershipAndManagement,
    RelevantDocuments,
    Contact,
    Maintenance,
    LeaseholdNotices,
    LeaseholdSafety,
    Consents,
    Complaints,
    LeaseholdAlterations,
    Enfranchisement,
    PdfPreview,
    AboutTA7,
    Modal,
    SummaryPage,
    FormsChangelogComponent,
  },
  mixins: [FormCompletion],
  data() {
    return {
      ta7Form: 0,
      showSubmitModal: false,
      wizardLoading: false,
      avoidForceRerender: false,
      disabled: false,
    };
  },
  props: ['entityType', 'entityId'],
  methods: {
    ...mapActions('sale', ['addTrackingEvent', 'setEventToHandle']),
    setLoading(value) {
      this.wizardLoading = value;
    },
    forceRerender() {
      this.ta7Form++;
    },
    saveLeaseholdInformation: function () {
      return this.$refs.leaseholdInformation.saveToApi();
    },
    saveOwnerShipAndManagement: function () {
      return this.$refs.ownerShipAndManagement.saveToApi();
    },
    saveRelevantDocuments: function () {
      return this.$refs.relevantDocuments.saveToApi();
    },
    saveContact: function () {
      return this.$refs.contact.saveToApi();
    },
    saveMaintenance: function () {
      return this.$refs.maintenance.saveToApi();
    },
    saveLeaseholdNotices: function () {
      return this.$refs.leaseholdNotices.saveToApi();
    },
    saveConsents: function () {
      return this.$refs.consents.saveToApi();
    },
    saveComplaints: function () {
      return this.$refs.complaints.saveToApi();
    },
    saveLeaseholdAlterations: function () {
      return this.$refs.leaseholdAlterations.saveToApi();
    },
    saveEnfranchisement: function () {
      return this.$refs.enfranchisement.saveToApi();
    },
    saveLeaseholdSafety: function () {
      return this.$refs.leaseholdSafety.saveToApi();
    },
    checkConfirmation: function () {
      return this.$refs.aboutTa7.confirm();
    },
    showPdfPreview: function (downloadFile = false) {
      return this.$refs.pdfPreview.getPdf(downloadFile, 'ta7-form-');
    },
    saveAndRedirect: async function () {
      this.navigate('ViewEntity', { entityType: this.entityType, entityId: this.entityId });
    },
    saveAndShowModalBeforeSubmit: async function () {
      this.showSubmitModal = true;
    },
    async submitForm(value) {
      this.showSubmitModal = false;
      if (value) {
        this.disabled = true;

        let metadata = '';
        let eventSlug = 'completed_ta7';
        let trackingEvent = {
          product_slug: this.entityType,
          entity_id: this.entityId,
          event_slug: eventSlug,
          metadata: metadata,
        };

        await Promise.all([
          PostTrackingEvent(trackingEvent),
          patchTa7Submitted({
            id: this.entityId,
          }),
        ])
          .then(() => {
            this.addTrackingEvent(trackingEvent);
            this.$gtag.event('Completed Milestone', {
              event_category: 'Task',
              event_label: 'Completed TA7',
            });

            this.setEventToHandle('completed_ta7');

            this.navigate('ViewEntity', {
              entityType: this.entityType,
              entityId: this.entityId,
            });
          })
          .catch((err) => console.debug(err));

        this.disabled = false;
      }
    },
    scrollToTop() {
      if (this.$router.currentRoute.name == 'ViewEntity') {
        this.$scrollTo('.page-header');
      }
    },
    allowForceRerender(value) {
      this.avoidForceRerender = value;
    },
  },
  watch: {
    loading: function () {
      if (!this.avoidForceRerender) {
        this.forceRerender();
      }
    },
  },
  computed: mapState({
    loading: (state) => state.loading,
  }),
};
</script>

<template>
  <form-wrapper :entityId="entityId" :entityType="entityType" class="mb-5">
    <div slot="form-content" class="home">
      <div :key="ta7Form">
        <form-wizard
          :color="$t('site.formColour')"
          errorColor="#F44336"
          stepSize="sm"
          subtitle=""
          title=""
          @on-loading="setLoading"
          @on-change="scrollToTop"
        >
          <tab-content title="Details">
            <property-and-client-details />
            <forms-changelog-component :entityId="entityId" :entityType="entityType" />
          </tab-content>

          <tab-content :before-change="checkConfirmation" title="About">
            <AboutTA7 ref="aboutTa7" :entityId="entityId" :entityType="entityType" />
          </tab-content>

          <tab-content :before-change="saveLeaseholdInformation" title="Leasehold information">
            <leasehold-information ref="leaseholdInformation"></leasehold-information>
          </tab-content>

          <tab-content :before-change="saveOwnerShipAndManagement" title="Ownership And Management">
            <ownership-and-management ref="ownerShipAndManagement"></ownership-and-management>
          </tab-content>

          <tab-content :before-change="saveRelevantDocuments" title="Relevant Documents">
            <relevant-documents ref="relevantDocuments"></relevant-documents>
          </tab-content>

          <tab-content :before-change="saveContact" title="Contact">
            <contact ref="contact"></contact>
          </tab-content>

          <tab-content :before-change="saveMaintenance" title="Maintenance">
            <maintenance ref="maintenance"></maintenance>
          </tab-content>

          <tab-content :before-change="saveLeaseholdNotices" title="Notices">
            <leasehold-notices ref="leaseholdNotices"></leasehold-notices>
          </tab-content>

          <tab-content :before-change="saveConsents" title="Consents">
            <consents ref="consents"></consents>
          </tab-content>

          <tab-content :before-change="saveComplaints" title="Complaints">
            <complaints ref="complaints"></complaints>
          </tab-content>

          <tab-content :before-change="saveLeaseholdAlterations" title="Leasehold alterations">
            <leasehold-alterations ref="leaseholdAlterations"></leasehold-alterations>
          </tab-content>

          <tab-content :before-change="saveEnfranchisement" title="Enfranchisement">
            <enfranchisement ref="enfranchisement"></enfranchisement>
          </tab-content>

          <tab-content title="Building safety" :before-change="saveLeaseholdSafety">
            <leasehold-safety ref="leaseholdSafety"></leasehold-safety>
          </tab-content>

          <tab-content title="Summary">
            <summary-page
              ref="summary"
              :disabled="disabled"
              :entityType="entityType"
              @download="showPdfPreview(true)"
              @preview="showPdfPreview"
              @save="saveAndRedirect"
              @submit="saveAndShowModalBeforeSubmit"
            ></summary-page>
          </tab-content>

          <!-- Customised Buttons -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left">
              <wizard-button
                v-if="props.activeTabIndex > 0"
                :style="props.fillButtonStyle"
                class="w-100 w-sm-auto bg-light border-dashed text-primary"
                @click.native="props.prevTab()"
                >Previous
              </wizard-button>
            </div>
            <div class="wizard-footer-right">
              <div v-if="!props.isLastStep">
                <wizard-button
                  :disabled="wizardLoading"
                  :style="props.fillButtonStyle"
                  class="w-100 w-sm-auto"
                  @click.native="props.nextTab()"
                >
                  Save and Continue
                  <i v-if="wizardLoading" class="icon-spinner11 ml-2 spinner"></i>
                  <i v-else class="icon-arrow-right5 ml-2"></i>
                </wizard-button>
              </div>
              <div v-else>
                <pdf-preview
                  ref="pdfPreview"
                  :entityId="entityId"
                  :entityType="entityType"
                  form-name="TA7"
                  @allowForceRerender="allowForceRerender"
                ></pdf-preview>
                <modal
                  v-if="showSubmitModal"
                  closeButtonText="Close"
                  proceedButtonText="Save And Submit"
                  @proceed="submitForm"
                  >Be aware that once you "Save And Submit", the form will be locked for any changes. Do you want submit
                  this form?
                </modal>
              </div>
            </div>
          </template>
        </form-wizard>
      </div>
    </div>
  </form-wrapper>
</template>
