<script>
import { mapMutations, mapState } from 'vuex';
import * as relevantDocumentsMutations from '@/store/relevantDocuments/mutations';
import patchRelevantDocuments from '@/api/buildings/patchRelevantDocuments';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import requiredDocument from '@/functions/supportingDocuments/relevantDocument';

const yesNoEnclosedToFollow = {
  YNETF_YES: 'Yes',
  YNETF_NO: 'No',
  YNETF_ENCLOSED: 'Attached',
  YNETF_TO_FOLLOW: 'To follow',
};

const enclosedToFollowSupplied = {
  ETFS_ENCLOSED: 'Attached',
  ETFS_TO_FOLLOW: 'To follow',
  ETFS_ALREADY_SUPPLIED: 'Already Supplied',
};

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const enclosedToFollowNotApplicable = {
  ETFNA_ENCLOSED: 'Attached',
  ETFNA_TO_FOLLOW: 'To follow',
  ETFNA_NOT_APPLICABLE: 'Not applicable',
};

export default {
  name: 'RelevantDocument',
  components: { SimpleQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_leaseSupplementalDeeds: (state) => state.building.relevantDocuments.leaseSupplementalDeeds,
      s_landlordRegulations: (state) => state.building.relevantDocuments.landlordRegulations,
      s_correspondence: (state) => state.building.relevantDocuments.correspondence,
      s_maintenanceInvoices: (state) => state.building.relevantDocuments.maintenanceInvoices,
      s_groundRentInvoices: (state) => state.building.relevantDocuments.groundRentInvoices,
      s_sellerBuildingsInsurance: (state) => state.building.relevantDocuments.sellerBuildingsInsurance,
      s_landlordBuildingsInsurance: (state) => state.building.relevantDocuments.landlordBuildingsInsurance,
      s_tenantsManagementCompany: (state) => state.building.relevantDocuments.tenantsManagementCompany,
      s_managementMemorandum: (state) => state.building.relevantDocuments.managementMemorandum,
      s_managementCertificate: (state) => state.building.relevantDocuments.managementCertificate,
      s_managementAccounts: (state) => state.building.relevantDocuments.managementAccounts,
      s_buildingId: (state) => state.building.buildingId,
    }),
    leaseSupplementalDeeds: {
      get() {
        return {
          answer: this.s_leaseSupplementalDeeds,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_LEASE_SUPPLEMENTAL_DEEDS}`
        ](value.answer);
      },
    },
    landlordRegulations: {
      get() {
        return {
          answer: this.s_landlordRegulations,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_LANDLORD_REGULATIONS}`
        ](value.answer);
      },
    },
    correspondence: {
      get() {
        return {
          answer: this.s_correspondence,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_CORRESPONDENCE}`
        ](value.answer);
      },
    },
    maintenanceInvoices: {
      get() {
        return {
          answer: this.s_maintenanceInvoices,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_MAINTENANCE_INVOICES}`
        ](value.answer);
      },
    },
    groundRentInvoices: {
      get() {
        return {
          answer: this.s_groundRentInvoices,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_GROUND_RENT_INVOICES}`
        ](value.answer);
      },
    },
    sellerBuildingsInsurance: {
      get() {
        return {
          answer: this.s_sellerBuildingsInsurance,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_SELLER_BUILDINGS_INSURANCE}`
        ](value.answer);
      },
    },
    landlordBuildingsInsurance: {
      get() {
        return {
          answer: this.s_landlordBuildingsInsurance,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_LANDLORD_BUILDINGS_INSURANCE}`
        ](value.answer);
      },
    },
    tenantsManagementCompany: {
      get() {
        return {
          answer: this.s_tenantsManagementCompany,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_TENANTS_MANAGEMENT_COMPANY}`
        ](value.answer);
      },
    },
    managementMemorandum: {
      get() {
        return {
          answer: this.s_managementMemorandum,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_MANAGEMENT_MEMORANDUM}`
        ](value.answer);
      },
    },
    managementCertificate: {
      get() {
        return {
          answer: this.s_managementCertificate,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_MANAGEMENT_CERTIFICATE}`
        ](value.answer);
      },
    },
    managementAccounts: {
      get() {
        return {
          answer: this.s_managementAccounts,
        };
      },
      set(value) {
        this[
          `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_MANAGEMENT_ACCOUNTS}`
        ](value.answer);
      },
    },
  },
  data() {
    return {
      saving: false,
      enclosedOrToFollow, // This refers to the content of the top on the file
      yesOrNo,
      enclosedToFollowNotApplicable,
      yesNoEnclosedToFollow,
      enclosedToFollowSupplied,
      requiredDocument,
    };
  },
  methods: {
    ...mapMutations([
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_LEASE_SUPPLEMENTAL_DEEDS}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_LANDLORD_REGULATIONS}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_CORRESPONDENCE}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_MAINTENANCE_INVOICES}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_GROUND_RENT_INVOICES}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_SELLER_BUILDINGS_INSURANCE}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_LANDLORD_BUILDINGS_INSURANCE}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_TENANTS_MANAGEMENT_COMPANY}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_MANAGEMENT_MEMORANDUM}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_MANAGEMENT_CERTIFICATE}`,
      `${relevantDocumentsMutations.RELEVANT_DOCUMENTS_PREFIX}${relevantDocumentsMutations.RELEVANT_DOCUMENTS_SET_MANAGEMENT_ACCOUNTS}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      await patchRelevantDocuments(
        this.s_buildingId,
        this.s_leaseSupplementalDeeds,
        this.s_landlordRegulations,
        this.s_correspondence,
        this.s_maintenanceInvoices,
        this.s_groundRentInvoices,
        this.s_sellerBuildingsInsurance,
        this.s_landlordBuildingsInsurance,
        this.s_tenantsManagementCompany,
        this.s_managementMemorandum,
        this.s_managementCertificate,
        this.s_managementAccounts,
      );
      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
  },
};
</script>

<template>
  <!-- Set page validation wrapper -->
  <ValidationObserver ref="observer">
    <div class="row">
      <div class="col">
        <h2 class="col-md-12">Relevant Documents</h2>

        <div class="mt-2 col-md-12">
          <h5>Please supply a copy of:</h5>
        </div>

        <div class="col-md-12">
          <simple-question
            v-model="leaseSupplementalDeeds"
            :dropdownOptions="enclosedToFollowSupplied"
            labelName="leaseSupplementalDeedsLabelName"
            titleNumberOrLetter="(a)"
            title="the lease and any supplemental deeds"
            validationFieldName="lease supplemental deeds"
          ></simple-question>

          <inline-file-uploader
            v-if="leaseSupplementalDeeds.answer === 'ETFS_ENCLOSED'"
            :requiredDocument="requiredDocument.leaseSupplementalDeeds"
          />
        </div>

        <div class="col-md-12">
          <simple-question
            v-model="landlordRegulations"
            :dropdownOptions="enclosedToFollowNotApplicable"
            labelName="landlordRegulationsLabelName"
            titleNumberOrLetter="(b)"
            title="any regulations made by the landlord or by the tenants'
                            management company additional to those in the lease"
            validationFieldName="landlord regulations"
          ></simple-question>

          <inline-file-uploader
            v-if="landlordRegulations.answer === 'ETFNA_ENCLOSED'"
            :requiredDocument="requiredDocument.landlordRegulations"
          />
        </div>

        <div class="border-top my-3"></div>

        <div class="col-md-12">
          <simple-question
            v-model="correspondence"
            :dropdownOptions="enclosedOrToFollow"
            labelName="correspondenceLabelName"
            title="Please supply a copy of any correspondence from the
                             landlord, the management company and the managing agent. "
            validationFieldName="correspondence"
          ></simple-question>

          <inline-file-uploader
            v-if="correspondence.answer === 'ETF_ENCLOSED'"
            :requiredDocument="requiredDocument.correspondence"
          />
        </div>

        <div class="border-top my-3"></div>

        <div class="mt-2 col-md-12">
          <h5>Please supply a copy of any invoices or demands and any statements and receipts for the payment of:</h5>
        </div>

        <div class="col-md-12">
          <simple-question
            v-model="maintenanceInvoices"
            :dropdownOptions="enclosedToFollowNotApplicable"
            labelName="maintenanceInvoicesLabelName"
            titleNumberOrLetter="(a)"
            title="maintenance or service charges for the last three years"
            validationFieldName="maintenance invoices"
          ></simple-question>

          <inline-file-uploader
            v-if="maintenanceInvoices.answer === 'ETFNA_ENCLOSED'"
            :requiredDocument="requiredDocument.maintenanceInvoices"
          />
        </div>

        <div class="col-md-12">
          <simple-question
            v-model="groundRentInvoices"
            :dropdownOptions="enclosedToFollowNotApplicable"
            labelName="groundRentInvoicesLabelName"
            titleNumberOrLetter="(b)"
            title="ground rent for the last three years "
            validationFieldName="ground rent invoices"
          ></simple-question>

          <inline-file-uploader
            v-if="groundRentInvoices.answer === 'ETFNA_ENCLOSED'"
            :requiredDocument="requiredDocument.groundRentInvoices"
          />
        </div>

        <div class="border-top my-3"></div>

        <div class="mt-2 col-md-12">
          <h5>Please supply a copy of the buildings insurance policy:</h5>
        </div>

        <div class="col-md-12">
          <simple-question
            v-model="sellerBuildingsInsurance"
            :dropdownOptions="enclosedOrToFollow"
            labelName="sellerBuildingsInsuranceLabelName"
            titleNumberOrLetter="(a)"
            title="arranged by the seller and a receipt for payment of the last premium, or"
            validationFieldName="seller buildings insurance"
          ></simple-question>

          <inline-file-uploader
            v-if="sellerBuildingsInsurance.answer === 'ETF_ENCLOSED'"
            :requiredDocument="requiredDocument.sellerBuildingsInsurance"
          />
        </div>

        <div class="col-md-12">
          <simple-question
            v-model="landlordBuildingsInsurance"
            :dropdownOptions="enclosedOrToFollow"
            labelName="landlordBuildingsInsuranceLabelName"
            titleNumberOrLetter="(b)"
            title="arranged by the landlord or management company and the schedule for the current year "
            validationFieldName="landlord buildings insurance"
          ></simple-question>

          <inline-file-uploader
            v-if="landlordBuildingsInsurance.answer === 'ETF_ENCLOSED'"
            :requiredDocument="requiredDocument.landlordBuildingsInsurance"
          />
        </div>

        <div class="border-top my-3"></div>

        <div class="col-md-12">
          <simple-question
            v-model="tenantsManagementCompany"
            :dropdownOptions="yesOrNo"
            labelName="tenantsManagementCompanyLabelName"
            title="Is the landlord a company controlled by the tenants and/or is the tenants’ management company or Right to Manage company managing the building? If Yes, please supply a copy of: "
            validationFieldName="tenants management company"
          ></simple-question>
        </div>
        <div v-if="tenantsManagementCompany.answer">
          <div class="col-md-12">
            <simple-question
              v-model="managementMemorandum"
              :dropdownOptions="enclosedOrToFollow"
              labelName="managementMemorandumLabelName"
              titleNumberOrLetter="(a)"
              title="the Memorandum and Articles of Association "
              validationFieldName="management memorandum"
            ></simple-question>

            <inline-file-uploader
              v-if="managementMemorandum.answer === 'ETF_ENCLOSED'"
              :requiredDocument="requiredDocument.managementMemorandum"
            />
          </div>

          <div class="col-md-12">
            <simple-question
              v-model="managementCertificate"
              :dropdownOptions="enclosedOrToFollow"
              labelName="managementCertificateLabelName"
              titleNumberOrLetter="(b)"
              title="the share or membership certificate "
              validationFieldName="management certificate"
            ></simple-question>

            <inline-file-uploader
              v-if="managementCertificate.answer === 'ETF_ENCLOSED'"
              :requiredDocument="requiredDocument.managementCertificate"
            />
          </div>

          <div class="col-md-12">
            <simple-question
              v-model="managementAccounts"
              :dropdownOptions="enclosedOrToFollow"
              labelName="managementAccountsLabelName"
              titleNumberOrLetter="(c)"
              title="the company accounts for the past three years "
              validationFieldName="management accounts"
            ></simple-question>

            <inline-file-uploader
              v-if="managementAccounts.answer === 'ETF_ENCLOSED'"
              :requiredDocument="requiredDocument.managementAccounts"
            />
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
  <!-- /set page validation wrapper -->
</template>
