<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/contact/mutations';
import patchContact from '@/api/buildings/patchContact';
import ContactDetails from '@/components/forms/inputGroups/ContactDetails';

export default {
  name: 'Contact',
  components: { ContactDetails },
  computed: {
    ...mapState({
      s_landlordName: (state) => state.building.contact.landlordName,
      s_landlordAddress: (state) => state.building.contact.landlordAddress,
      s_landlordTel: (state) => state.building.contact.landlordTel,
      s_landlordEmail: (state) => state.building.contact.landlordEmail,
      s_landlordAgentName: (state) => state.building.contact.landlordAgentName,
      s_landlordAgentAddress: (state) => state.building.contact.landlordAgentAddress,
      s_landlordAgentTel: (state) => state.building.contact.landlordAgentTel,
      s_landlordAgentEmail: (state) => state.building.contact.landlordAgentEmail,
      s_tenantAgentName: (state) => state.building.contact.tenantAgentName,
      s_tenantAgentAddress: (state) => state.building.contact.tenantAgentAddress,
      s_tenantAgentTel: (state) => state.building.contact.tenantAgentTel,
      s_tenantAgentEmail: (state) => state.building.contact.tenantAgentEmail,
      s_building: (state) => state.building,
      s_buildingId: (state) => state.building.buildingId,
    }),
    landlord: {
      get() {
        return {
          name: this.s_landlordName,
          address: this.s_landlordAddress,
          tel: this.s_landlordTel,
          email: this.s_landlordEmail,
        };
      },
      set(value) {
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_NAME}`](value.name);
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_ADDRESS}`](value.address);
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_TEL}`](value.tel);
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_EMAIL}`](value.email);
      },
    },
    landlordAgent: {
      get() {
        return {
          name: this.s_landlordAgentName,
          address: this.s_landlordAgentAddress,
          tel: this.s_landlordAgentTel,
          email: this.s_landlordAgentEmail,
        };
      },
      set(value) {
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_AGENT_NAME}`](value.name);
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_AGENT_ADDRESS}`](value.address);
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_AGENT_TEL}`](value.tel);
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_AGENT_EMAIL}`](value.email);
      },
    },
    tenantAgent: {
      get() {
        return {
          name: this.s_tenantAgentName,
          address: this.s_tenantAgentAddress,
          tel: this.s_tenantAgentTel,
          email: this.s_tenantAgentEmail,
        };
      },
      set(value) {
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_TENANT_AGENT_NAME}`](value.name);
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_TENANT_AGENT_ADDRESS}`](value.address);
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_TENANT_AGENT_TEL}`](value.tel);
        this[`${mutations.CONTACT_PREFIX}${mutations.CONTACT_TENANT_AGENT_EMAIL}`](value.email);
      },
    },
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    ...mapMutations([
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_NAME}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_ADDRESS}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_TEL}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_EMAIL}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_AGENT_NAME}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_AGENT_ADDRESS}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_AGENT_TEL}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_LANDLORD_AGENT_EMAIL}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_TENANT_AGENT_NAME}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_TENANT_AGENT_ADDRESS}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_TENANT_AGENT_TEL}`,
      `${mutations.CONTACT_PREFIX}${mutations.CONTACT_TENANT_AGENT_EMAIL}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      await patchContact(
        this.s_buildingId,
        this.s_landlordName,
        this.s_landlordAddress,
        this.s_landlordTel,
        this.s_landlordEmail,
        this.s_landlordAgentName,
        this.s_landlordAgentAddress,
        this.s_landlordAgentTel,
        this.s_landlordAgentEmail,
        this.s_tenantAgentName,
        this.s_tenantAgentAddress,
        this.s_tenantAgentTel,
        this.s_tenantAgentEmail,
      );
      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error))
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Contact details</h2>

      <p class="text-uppercase">
        Please supply contact details for the following, where appropriate. (The landlord may be, for example, a private
        individual, a housing association, or a management company owned by the residents. A managing agent may be
        employed by the landlord or by the tenants’ management company to collect the rent and/or manage the building.)
      </p>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <div class="row">
          <div class="col-md-6">
            <h5 class="text-center mb-2">Landlord</h5>
            <contact-details fieldName="landlordField" v-model="landlord" :required="true"></contact-details>
          </div>
          <div class="col-md-6">
            <h5 class="text-center mb-2">Managing agent contracted by the landlord</h5>
            <contact-details fieldName="landlordAgentField" v-model="landlordAgent"></contact-details>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <h5 class="text-center mb-2">Managing agent contracted by the tenants’ management company</h5>
            <contact-details fieldName="tenantAgentField" v-model="tenantAgent"></contact-details>
          </div>
        </div>
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
