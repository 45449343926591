<script>
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

export default {
  name: 'ContactDetails',
  components: { AddressLookup },
  data() {
    return {
      isRequired: false,
    };
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    showLabels: {
      type: Boolean,
      default: () => true,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Object,
      default: () => ({
        name: '',
        address: '',
        tel: '',
        email: '',
      }),
    },
  },
  filters: {
    pascalToWords(word) {
      return lowerCase(startCase(word));
    },
  },
  methods: {
    triggerUpdate() {
      this.$emit('input', this.value);
    },
    validateAddress(a) {
      if (a !== '' && a !== null && typeof a === 'object') {
        return Object.values(a).filter((v) => v !== '' && v !== null && typeof v !== 'undefined').length > 0;
      }

      return false;
    },
    getValidationState({ dirty, validated, valid = null }) {
      if (this.required) {
        this.isRequired = true;
      } else {
        this.isRequired =
          (this.value.name !== '' && this.value.name !== null && typeof this.value.name !== 'undefined') ||
          this.validateAddress(this.value.address) ||
          (this.value.tel !== '' && this.value.tel !== null && typeof this.value.tel !== 'undefined') ||
          (this.value.email !== '' && this.value.email !== null && typeof this.value.email !== 'undefined');
      }

      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div>
    <validation-provider
      :name="`${fieldName} name` | pascalToWords"
      :rules="{ required: isRequired }"
      v-slot="validationContext"
    >
      <div class="form-group row">
        <label :class="isRequired ? 'mandatory' : ''" :for="`${fieldName}name`" class="col-sm-3 col-form-label"
          >Name</label
        >
        <div class="col-sm-9">
          <b-form-input
            v-model.trim="value.name"
            :id="`${fieldName}name`"
            ref="name"
            :state="getValidationState(validationContext)"
            @input="triggerUpdate"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </validation-provider>

    <address-lookup
      v-model="value.address"
      id="address"
      :addResidency="false"
      @input="triggerUpdate"
      :requiresValidation="isRequired"
    />

    <validation-provider
      :name="`${fieldName} telephone` | pascalToWords"
      :rules="{ required: isRequired, phone_number: isRequired }"
      v-slot="validationContext"
    >
      <div class="form-group row">
        <label :class="isRequired ? 'mandatory' : ''" :for="`${fieldName}tel`" class="col-sm-3 col-form-label"
          >Telephone</label
        >
        <div class="col-sm-9">
          <b-form-input
            v-model="value.tel"
            :id="`${fieldName}telephone`"
            class="form-control"
            ref="tel"
            :state="getValidationState(validationContext)"
            @input="triggerUpdate"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="tel-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </validation-provider>

    <validation-provider
      :name="`${fieldName} email` | pascalToWords"
      :rules="{ required: isRequired, email: isRequired }"
      v-slot="validationContext"
    >
      <div class="form-group row">
        <label :class="isRequired ? 'mandatory' : ''" :for="`${fieldName}email`" class="col-sm-3 col-form-label"
          >Email</label
        >
        <div class="col-sm-9">
          <b-form-input
            type="email"
            :id="`${fieldName}email`"
            class="form-control"
            :state="getValidationState(validationContext)"
            v-model="value.email"
            @input="triggerUpdate"
            ref="email"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="email-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </validation-provider>
  </div>
</template>
