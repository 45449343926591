import { LEASE_TERMS_CHANGE_DOCUMENT } from '@/values/fileSlugs';

export default {
  leaseTermsChangeDocument: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: LEASE_TERMS_CHANGE_DOCUMENT, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'leaseTermsChangeDocument',
      answers: ['ETFLD_ENCLOSED', 'ETFLD_TO_FOLLOW'],
      mainText:
        'Is the seller aware of any changes in the terms of the lease or of the landlord giving any consents under the lease?',
      documentText: 'Please supply a copy',
    },
  },
};
