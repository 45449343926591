//alterations
export const CERTIFICATES = 'certificates';
export const SOLAR_PANELS_LONG_LEASE_DOCUMENTS = 'solar_panels_long_lease_documents';
export const LISTED_BUILDING_CONSERVATION_AREA_DOCUMENTS = 'listed_building_conservation_area_documents';
export const PRESERVATION_ORDER_RELEVANT_DOCUMENTS = 'preservation_order_relevant_documents';
//boundaries
export const PARTY_WALL_ACT = 'party_wall_act';
//consents
export const LEASE_TERMS_CHANGE_DOCUMENT = 'lease_terms_change_document';
//enfranchisement
export const SELLER_PURCHASE_NOTICE_COPY = 'seller_purchase_notice_copy';
export const COLLECTIVE_PURCHASE_NOTICE_COPY = 'collective_purchase_notice_copy';
export const PURCHASE_NOTICE_RESPONSES_COPY = 'purchase_notice_responses_copy';
//environmentalMatters
export const FLOOD_RISK_REPORT_COPY = 'flood_risk_report_copy';
export const RADON_TEST_REPORT = 'radon_test_report';
export const EPC_COPY = 'epc_copy';
export const INSTALLATIONS_GREEN_DEAL_FINANCED = 'installations_green_deal_financed';
export const JAPANESE_KNOTWEED_MANAGEMENT_PLAN_COPY = 'japanese_knotweed_management_plan_copy';
//guarantees
export const NEW_HOME_WARRANTY_COPY = 'new_home_warranty_copy';
export const DAMP_PROOFING_COPY = 'damp_proofing_copy';
export const TIMBER_TREATMENT_COPY = 'timber_treatment_copy';
export const WINDOWS_COPY = 'windows_copy';
export const ELECTRICAL_WORK_COPY = 'electrical_work_copy';
export const ROOFING_COPY = 'roofing_copy';
export const CENTRAL_HEATING_COPY = 'central_heating_copy';
export const UNDERPINNING_COPY = 'underpinning_copy';
export const GUARANTEES_OTHER = 'guarantees_other';
//rights
export const PIPES_AGREEMENT_COPY = 'pipes_agreement_copy';
//leaseholdAlterations
export const CONSENT_OBTAINED_DOCUMENT = 'consent_obtained_document';
//leaseholdNotices
export const SALE_NOTICE_COPY = 'sale_notice_copy';
export const GENERAL_NOTICE_COPY = 'general_notice_copy';
//occupiers
export const AGREE_TO_SALE_CONTRACT_DOCUMENTS = 'agree_to_sale_contract_documents';
//relevantDocument
export const LEASE_SUPPLEMENTAL_DEEDS = 'lease_supplemental_deeds';
export const LANDLORD_REGULATIONS = 'landlord_regulations';
export const CORRESPONDENCE = 'correspondence';
export const MAINTENANCE_INVOICES = 'maintenance_invoices';
export const GROUND_RENT_INVOICES = 'ground_rent_invoices';
export const SELLER_BUILDINGS_INSURANCE = 'seller_buildings_insurance';
export const LANDLORD_BUILDINGS_INSURANCE = 'landlord_buildings_insurance';
export const MANAGEMENT_MEMORANDUM = 'management_memorandum';
export const MANAGEMENT_CERTIFICATE = 'management_certificate';
export const MANAGEMENT_ACCOUNTS = 'management_accounts';
//services
export const ELECTRICS_TESTED_CERTIFICATE = 'electrics_tested_certificate';
export const ELECTRICAL_SAFETY_CERTIFICATE = 'electrical_safety_certificate';
export const COMPLIANCE_CERTIFICATE = 'compliance_certificate';
export const COMPLETION_CERTIFICATE = 'completion_certificate';
export const CENTRAL_HEATING_BEFORE_SPECIFIED_DATE = 'central_heating_before_specified_date';
export const HEATING_SYSTEM_SERVICED_INSPECTION_REPORT = 'heating_system_serviced_inspection_report';
export const SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY_DOCUMENTS = 'sewerage_system_outside_of_property_documents';
// remortgage - bank statement
export const BANK_STATEMENT = 'bank_statement';
// transfer of equity (remortgage) - court order
export const COURT_ORDER = 'court_order';
// Building Safety (TA7)
export const REMEDIATION_WORKS_COPY = 'remediation_works_copy';
export const DEED_OF_CERTIFICATE_COPY = 'deed_of_certificate_copy';
export const LANDLORDS_CERTIFICATE_COPY = 'landlords_certificate_copy';
