<script>
import { mapMutations, mapState } from 'vuex';
import * as leaseholdAlterationsMutations from '@/store/leaseholdAlterations/mutations';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchLeaseholdAlterations from '@/api/buildings/patchLeaseholdAlterations';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import leaseholdAlterations from '@/functions/supportingDocuments/leaseholdAlterations';

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrNotKnownOrNotRequired = {
  TFNKNR_YES: 'Yes',
  TFNKNR_NO: 'No',
  TFNKNR_NOT_KNOWN: 'Not Known',
  TFNKNR_NOT_REQUIRED: 'Not Required',
};

export default {
  name: 'LeaseholdAlterations',
  components: { SimpleQuestion, OpenQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_alterationsSinceLeaseGranted: (state) => state.building.leaseholdAlterations.alterationsSinceLeaseGranted,
      s_alterationsSinceLeaseGrantedDescription: (state) =>
        state.building.leaseholdAlterations.alterationsSinceLeaseGrantedDescription,
      s_consentObtained: (state) => state.building.leaseholdAlterations.consentObtained,
      s_consentObtainedDocument: (state) => state.building.leaseholdAlterations.consentObtainedDocument,
      s_buildingId: (state) => state.buildingId,
    }),
    alterationsSinceLeaseGranted: {
      get() {
        return {
          answer: this.s_alterationsSinceLeaseGranted,
          description: this.s_alterationsSinceLeaseGrantedDescription,
        };
      },
      set(value) {
        this[
          `${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_PREFIX}${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED}`
        ](value.answer);
        this[
          `${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_PREFIX}${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED_DESCRIPTION}`
        ](value.description);
      },
    },
    consentObtained: {
      get() {
        return {
          answer: this.s_consentObtained,
        };
      },
      set(value) {
        this[
          `${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_PREFIX}${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED}`
        ](value.answer);
      },
    },
    consentObtainedDocument: {
      get() {
        return {
          answer: this.s_consentObtainedDocument,
        };
      },
      set(value) {
        this[
          `${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_PREFIX}${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED_DOCUMENT}`
        ](value.answer);
      },
    },
  },
  data() {
    return {
      saving: false,
      enclosedOrToFollow, // This refers to the content of the top on the file
      yesOrNo,
      yesOrNoOrNotKnownOrNotRequired,
      leaseholdAlterations,
    };
  },
  methods: {
    ...mapMutations([
      `${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_PREFIX}${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED}`,
      `${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_PREFIX}${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED_DESCRIPTION}`,
      `${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_PREFIX}${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED}`,
      `${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_PREFIX}${leaseholdAlterationsMutations.LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED_DOCUMENT}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchLeaseholdAlterations(
        this.s_buildingId,
        this.s_alterationsSinceLeaseGranted,
        this.s_alterationsSinceLeaseGrantedDescription,
        this.s_consentObtained,
        this.s_consentObtainedDocument,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <h2 class="col-md-12">Leasehold Alterations</h2>

        <div class="col-md-12">
          <open-question
            dropdownName="alterationsSinceLeaseGrantedDropdownName"
            labelName="alterationsSinceLeaseGrantedLabelName"
            textAreaName="alterationsSinceLeaseGrantedTextAreaName"
            title="Is the seller aware of any alterations having been made to the property since the lease was originally granted?
                        If Yes, please give details of these alterations: "
            v-model="alterationsSinceLeaseGranted"
            validationFieldName="alterations since lease granted"
          ></open-question>
        </div>

        <div class="col-md-12" v-if="alterationsSinceLeaseGranted.answer">
          <simple-question
            :dropdownOptions="yesOrNoOrNotKnownOrNotRequired"
            labelName="consentObtainedLabelName"
            title="Was the landlord’s consent for the alterations obtained? If Yes, please supply a copy. "
            v-model="consentObtained"
            validationFieldName="consent obtained"
          ></simple-question>
        </div>

        <div class="col-md-12" v-if="consentObtained.answer === 'TFNKNR_YES' && alterationsSinceLeaseGranted.answer">
          <simple-question
            :dropdownOptions="enclosedOrToFollow"
            labelName="consentObtainedDocumentLabelName"
            title=" "
            v-model="consentObtainedDocument"
            validationFieldName="consent obtained document"
          ></simple-question>

          <inline-file-uploader
            v-if="consentObtainedDocument.answer === 'ETF_ENCLOSED'"
            :requiredDocument="leaseholdAlterations.consentObtainedDocument"
          />
        </div>
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
