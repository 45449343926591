<script>
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';

export default {
  name: 'SimpleQuestion',
  props: {
    titleNumberOrLetter: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    dropdownOptions: {
      type: Object,
      default: () => ({}),
    },
    validationFieldName: {
      type: String,
      required: true,
    },
    labelName: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({
        answer: null,
      }),
    },
    lockChange: {
      type: Boolean,
      default: () => false,
    },
    removeSectionSpacing: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedValue:
        this.value.answer !== '' && this.value.answer != 'ETF_UNSPECIFIED' && typeof this.value.answer !== 'undefined'
          ? this.value.answer
          : null,
    };
  },
  methods: {
    triggerUpdate() {
      let selected = this.selectedValue;
      if (selected === 'true' || selected === 'false') {
        selected = selected === 'true';
      }

      this.$emit('input', {
        answer: selected,
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  computed: {
    dropdownComputedOptions: function () {
      return reduce(
        this.dropdownOptions,
        (accumulator, value, key) => {
          const option = {
            value: key,
            text: value,
          };
          accumulator.push(option);
          return accumulator;
        },
        [{ value: null, text: 'Please choose', disabled: true, selected: true }],
      );
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.selectedValue = newVal.answer;
      }
    },
  },
};
</script>

<template>
  <div :class="removeSectionSpacing ? 'row' : 'row my-3'">
    <div class="col-md-9">
      <div>
        <div v-if="titleNumberOrLetter">
          <div class="row">
            <div class="col-1">
              <h5>
                <label>{{ titleNumberOrLetter }}</label>
              </h5>
            </div>
            <div class="col-11">
              <h5>
                <label :name="labelName" class="mandatory">{{ title }}</label>
              </h5>
            </div>
          </div>
        </div>
        <div v-else>
          <h5>
            <label :name="labelName" class="mandatory">{{ title }}</label>
          </h5>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <validation-provider :name="validationFieldName" rules="required" v-slot="validationContext">
        <b-form-select
          :options="dropdownComputedOptions"
          :state="getValidationState(validationContext)"
          aria-describedby="answer-type-error"
          class="form-control bg-white"
          :name="validationFieldName"
          v-model="selectedValue"
          :disabled="lockChange"
          @change="triggerUpdate"
          ref="answer"
        ></b-form-select>
        <b-form-invalid-feedback data-error-name="answer-type-error"
          >{{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </div>
  </div>
</template>
