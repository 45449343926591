import axios from 'axios';

export default (id, landlordComplaint, landlordComplaintDescription, sellerComplaint, sellerComplaintDescription) => {
  const preparedData = {};
  preparedData.landlord_complaint = landlordComplaint;
  preparedData.landlord_complaint_description = landlordComplaint ? landlordComplaintDescription : '';

  preparedData.seller_complaint = sellerComplaint;
  preparedData.seller_complaint_description = sellerComplaint ? sellerComplaintDescription : '';

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, {
    complaint: preparedData,
  });
};
