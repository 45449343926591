import axios from 'axios';

export default (
  id,
  freeholdControlledByTenants,
  freeholdNotControlledByTenants,
  headLeaseholderPresent,
  headLeaseholderControlledByTenants,
  managingBuildingFreeholderResponsible,
  managingBuildingLeaseholderResponsible,
  managingBuildingCompanyResponsible,
  managingBuildingRightToManageCompanyResponsible,
  managingBuildingOtherResponsible,
  managementCompanyDissolved,
  managingAgentForRentOrManagement,
) => {
  const preparedData = {
    ownership: {
      freehold_controlled_by_tenants: freeholdControlledByTenants,
      freehold_not_controlled_by_tenants: freeholdNotControlledByTenants,
      head_leaseholder_present: headLeaseholderPresent,
      head_leaseholder_controlled_by_tenants: headLeaseholderControlledByTenants,
      managing_building_freeholder_responsible: managingBuildingFreeholderResponsible,
      managing_building_leaseholder_responsible: managingBuildingLeaseholderResponsible,
      managing_building_company_responsible: managingBuildingCompanyResponsible,
      managing_building_right_to_manage_company_responsible: managingBuildingRightToManageCompanyResponsible,
      managing_building_other_responsible: managingBuildingOtherResponsible,
      management_company_dissolved: managementCompanyDissolved,
      managing_agent_for_rent_or_management: managingAgentForRentOrManagement,
    },
  };

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, preparedData);
};
