<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/leaseholdSafety/mutations';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import leaseholdSafety from '@/functions/supportingDocuments/leaseholdSafety';
import patchLeaseholdSafety from '@/api/buildings/patchLeaseholdSafety';

const yesOrNoOrNotApplicable = {
  YNNA_YES: 'Yes',
  YNNA_NO: 'No',
  YNNA_NO_APPLICABLE: 'Not applicable',
};

const attachedOrToFollow = {
  ATF_ATTACHED: 'Attached',
  ATF_TO_FOLLOW: 'To follow',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const currentOrPreviousLeaseholder = {
  CLPL_CURRENT_LEASEHOLDER: 'Current leaseholder',
  CLPL_PREVIOUS_LEASEHOLDER: 'Previous leaseholder',
};

export default {
  name: 'LeaseholdSafety',
  components: { SimpleQuestion, InlineFileUploader },
  computed: {
    ...mapState({
      s_remediationWorks: (state) => state.building.leaseholdSafety.remediationWorks,
      s_remediationWorksCopy: (state) => state.building.leaseholdSafety.remediationWorksCopy,
      s_qualifyingLease: (state) => state.building.leaseholdSafety.qualifyingLease,
      s_deedOfCertificate: (state) => state.building.leaseholdSafety.deedOfCertificate,
      s_deedOfCertificateCompletion: (state) => state.building.leaseholdSafety.deedOfCertificateCompletion,
      s_deedOfCertificateCopy: (state) => state.building.leaseholdSafety.deedOfCertificateCopy,
      s_notifiedOfSellIntention: (state) => state.building.leaseholdSafety.notifiedOfSellIntention,
      s_landlordsCertificateReceived: (state) => state.building.leaseholdSafety.landlordsCertificateReceived,
      s_landlordsCertificateCopy: (state) => state.building.leaseholdSafety.landlordsCertificateCopy,
      s_buildingId: (state) => state.building.buildingId,
    }),
    remediationWorks: {
      get() {
        return {
          answer: this.s_remediationWorks,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS}`](value.answer);
      },
    },
    remediationWorksCopy: {
      get() {
        return {
          answer: this.s_remediationWorksCopy,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS_COPY}`](
          value.answer,
        );
      },
    },
    qualifyingLease: {
      get() {
        return {
          answer: this.s_qualifyingLease,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_QUALIFYING_LEASE}`](value.answer);
      },
    },
    deedOfCertificate: {
      get() {
        return {
          answer: this.s_deedOfCertificate,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE}`](value.answer);
      },
    },
    deedOfCertificateCompletion: {
      get() {
        return {
          answer: this.s_deedOfCertificateCompletion,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COMPLETION}`](
          value.answer,
        );
      },
    },
    deedOfCertificateCopy: {
      get() {
        return {
          answer: this.s_deedOfCertificateCopy,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COPY}`](
          value.answer,
        );
      },
    },
    notifiedOfSellIntention: {
      get() {
        return {
          answer: this.s_notifiedOfSellIntention,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_NOTIFIED_OF_SELL_INTENTION}`](
          value.answer,
        );
      },
    },
    landlordsCertificateReceived: {
      get() {
        return {
          answer: this.s_landlordsCertificateReceived,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_RECEIVED}`](
          value.answer,
        );
      },
    },
    landlordsCertificateCopy: {
      get() {
        return {
          answer: this.s_landlordsCertificateCopy,
        };
      },
      set(value) {
        this[`${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_COPY}`](
          value.answer,
        );
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      yesOrNoOrNotApplicable,
      attachedOrToFollow,
      currentOrPreviousLeaseholder,
      leaseholdSafety,
    };
  },
  methods: {
    ...mapMutations([
      `${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS}`,
      `${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS_COPY}`,
      `${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_QUALIFYING_LEASE}`,
      `${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE}`,
      `${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COMPLETION}`,
      `${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COPY}`,
      `${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_NOTIFIED_OF_SELL_INTENTION}`,
      `${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_RECEIVED}`,
      `${mutations.LEASEHOLD_SAFETY_PREFIX}${mutations.LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_COPY}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchLeaseholdSafety(
        this.s_buildingId,
        this.s_remediationWorks,
        this.s_remediationWorksCopy,
        this.s_qualifyingLease,
        this.s_deedOfCertificate,
        this.s_deedOfCertificateCompletion,
        this.s_deedOfCertificateCopy,
        this.s_notifiedOfSellIntention,
        this.s_landlordsCertificateReceived,
        this.s_landlordsCertificateCopy,
      );

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-12">Building Safety</h2>

      <div class="col-12">
        <div class="section-note">
          <p>
            <b>Note:</b> The Building Safety Act 2022 introduced leaseholder protections for qualifying leaseholders.
            Guidance about the protections for leaseholders is available at:
            <b>https://www.gov.uk/guidance/building-safety-leaseholder-protections-guidance-for-leaseholders</b>
          </p>

          <p class="my-3">
            <b>Note:</b> A deed of certificate confirms whether the leaseholder is eligible for the leaseholder
            protections. Guidance about the leaseholder deed of certificate is available at:
            <b>https://www.gov.uk/guidance/mandatory-information-required-from-leaseholders-and-building-owners</b>
            and frequently asked questions about the leaseholder deed of certificate is available at:
            <b>https://www.gov.uk/guidance/leaseholder-protections-deed-of-certificate-frequently-asked-questions</b>
          </p>
        </div>
      </div>

      <div class="border-top my-3"></div>
      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <div class="col-12">
          <simple-question
            :dropdownOptions="yesOrNoOrNotApplicable"
            labelName="remediationWorksLabelName"
            title="Have any remediation works on the building been proposed or carried out?"
            v-model="remediationWorks"
            validationFieldName="remediation works"
          />
        </div>

        <div class="col-12" v-if="remediationWorks.answer === 'YNNA_YES'">
          <simple-question
            :dropdownOptions="attachedOrToFollow"
            labelName="remediationWorksCopyLabelName"
            title="If Yes, please provide details of the remediation works proposed and evidence of any carried out."
            v-model="remediationWorksCopy"
            validationFieldName="remediation works proposed and evidence"
          />

          <inline-file-uploader
            v-if="remediationWorksCopy.answer === 'ATF_ATTACHED'"
            :requiredDocument="leaseholdSafety.remediationWorksCopy"
          />
        </div>

        <div class="col-12 border-top">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="qualifyingLeaseLabelName"
            title="Is the lease of the property a qualifying lease?"
            v-model="qualifyingLease"
            validationFieldName="qualifying lease"
          />
        </div>

        <div class="col-12 border-top">
          <simple-question
            :dropdownOptions="yesOrNoOrNotApplicable"
            labelName="deedOfCertificateLabelName"
            title="Is there a Leaseholder Deed of Certificate for the property?"
            v-model="deedOfCertificate"
            validationFieldName="leaseholder Deed of Certificate"
          />
        </div>

        <div v-if="deedOfCertificate.answer === 'YNNA_YES'">
          <div class="col-12">
            <p>If Yes:</p>
          </div>

          <div class="col-12">
            <simple-question
              :dropdownOptions="currentOrPreviousLeaseholder"
              labelName="deedOfCertificateCompletionLabelName"
              title="Did the seller (the current leaseholder) complete the deed of certificate or was it completed by a previous leaseholder?"
              v-model="deedOfCertificateCompletion"
              validationFieldName="complete the deed of certificate"
            />
          </div>

          <div class="col-12">
            <simple-question
              :dropdownOptions="attachedOrToFollow"
              labelName="deedOfCertificateCopyLabelName"
              title="Please supply a copy of the leaseholder deed of certificate and the accompanying evidence."
              v-model="deedOfCertificateCopy"
              validationFieldName="copy of the leaseholder deed of certificate"
            />

            <inline-file-uploader
              v-if="deedOfCertificateCopy.answer === 'ATF_ATTACHED'"
              :requiredDocument="leaseholdSafety.deedOfCertificateCopy"
            />
          </div>
        </div>

        <div class="col-12 border-top">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="notifiedOfSellIntentionLabelName"
            title="Has the freeholder / landlord been notified of the intention to sell?"
            v-model="notifiedOfSellIntention"
            validationFieldName="notified of the intention to sell"
          />
        </div>

        <div class="col-12 border-top">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="landlordsCertificateReceivedLabelName"
            title="Has the seller received a Landlord’s Certificate and the accompanying evidence?"
            v-model="landlordsCertificateReceived"
            validationFieldName="received a Landlord’s Certificate"
          />
        </div>

        <div class="col-12" v-if="landlordsCertificateReceived.answer">
          <simple-question
            :dropdownOptions="attachedOrToFollow"
            labelName="landlordsCertificateCopyLabelName"
            title="If Yes, please supply a copy of the Landlord’s Certificate and the accompanying evidence."
            v-model="landlordsCertificateCopy"
            validationFieldName="copy of the Landlord’s Certificate"
          />

          <inline-file-uploader
            v-if="landlordsCertificateCopy.answer === 'ATF_ATTACHED'"
            :requiredDocument="leaseholdSafety.landlordsCertificateCopy"
          />
        </div>
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
