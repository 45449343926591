<script>
import { mapMutations, mapState } from 'vuex';
import * as maintenanceMutations from '@/store/maintenance/mutations';
import patchMaintenance from '@/api/buildings/patchMaintenance';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import FreeTypeYearQuestion from '@/components/forms/inputGroups/FreeTypeYearQuestion';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const buildingInsuranceOptions = {
  IO_SELLER: 'Seller',
  IO_MANAGEMENT_COMPANY: 'Management company',
  IO_LANDLORD: 'Landlord',
};

export default {
  name: 'MaintenanceAndServiceCharge',
  components: {
    SimpleQuestion,
    OpenQuestion,
    FreeTypeYearQuestion,
  },
  computed: {
    ...mapState({
      s_buildingId: (state) => state.building.buildingId,
      s_buildingInsurance: (state) => state.building.maintenance.buildingInsurance,
      s_externalDecorated: (state) => state.building.maintenance.externalDecorated,
      s_internalDecorated: (state) => state.building.maintenance.internalDecorated,
      s_sellerMaintenanceContribution: (state) => state.building.maintenance.sellerMaintenanceContribution,
      s_decorationExpenses: (state) => state.building.maintenance.decorationExpenses,
      s_decorationExpensesDescription: (state) => state.building.maintenance.decorationExpensesDescription,
      s_managementProblems: (state) => state.building.maintenance.managementProblems,
      s_managementProblemsDescription: (state) => state.building.maintenance.managementProblemsDescription,
      s_buildingSafetyRiskExistence: (state) => state.building.maintenance.buildingSafetyRiskExistence,
      s_buildingSafetyRiskExistenceDescription: (state) =>
        state.building.maintenance.buildingSafetyRiskExistenceDescription,
      s_serviceChargeChallenge: (state) => state.building.maintenance.serviceChargeChallenge,
      s_serviceChargeChallengeDescription: (state) => state.building.maintenance.serviceChargeChallengeDescription,
      s_serviceChargeCollectionDifficulties: (state) => state.building.maintenance.serviceChargeCollectionDifficulties,
      s_serviceChargeCollectionDifficultiesDescription: (state) =>
        state.building.maintenance.serviceChargeCollectionDifficultiesDescription,
      s_serviceChargeOwing: (state) => state.building.maintenance.serviceChargeOwing,
      s_serviceChargeOwingDescription: (state) => state.building.maintenance.serviceChargeOwingDescription,
    }),
    buildingInsurance: {
      get() {
        return {
          answer: this.s_buildingInsurance,
        };
      },
      set(value) {
        this[`${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_BUILDING_INSURANCE}`](
          value.answer,
        );
      },
    },
    externalDecorated: {
      get() {
        return this.s_externalDecorated;
      },
      set(value) {
        this[`${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_EXTERNAL_DECORATED}`](
          value,
        );
      },
    },
    internalDecorated: {
      get() {
        return this.s_internalDecorated;
      },
      set(value) {
        this[`${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_INTERNAL_DECORATED}`](
          value,
        );
      },
    },
    sellerMaintenanceContribution: {
      get() {
        return {
          answer: this.s_sellerMaintenanceContribution,
        };
      },
      set(value) {
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SELLER_MAINTENANCE_CONTRIBUTION}`
        ](value.answer);
      },
    },
    decorationExpenses: {
      get() {
        return {
          answer: this.s_decorationExpenses,
          description: this.s_decorationExpensesDescription,
        };
      },
      set(value) {
        this[`${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_DECORATION_EXPENSES}`](
          value.answer,
        );
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_DECORATION_EXPENSES_DESCRIPTION}`
        ](value.description);
      },
    },
    managementProblems: {
      get() {
        return {
          answer: this.s_managementProblems,
          description: this.s_managementProblemsDescription,
        };
      },
      set(value) {
        this[`${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_MANAGEMENT_PROBLEMS}`](
          value.answer,
        );
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_MANAGEMENT_PROBLEMS_DESCRIPTION}`
        ](value.description);
      },
    },
    buildingSafetyRiskExistence: {
      get() {
        return {
          answer: this.s_buildingSafetyRiskExistence,
          description: this.s_buildingSafetyRiskExistenceDescription,
        };
      },
      set(value) {
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE}`
        ](value.answer);
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE_DESCRIPTION}`
        ](value.description);
      },
    },
    serviceChargeChallenge: {
      get() {
        return {
          answer: this.s_serviceChargeChallenge,
          description: this.s_serviceChargeChallengeDescription,
        };
      },
      set(value) {
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE}`
        ](value.answer);
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE_DESCRIPTION}`
        ](value.description);
      },
    },
    serviceChargeCollectionDifficulties: {
      get() {
        return {
          answer: this.s_serviceChargeCollectionDifficulties,
          description: this.s_serviceChargeCollectionDifficultiesDescription,
        };
      },
      set(value) {
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES}`
        ](value.answer);
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES_DESCRIPTION}`
        ](value.description);
      },
    },
    serviceChargeOwing: {
      get() {
        return {
          answer: this.s_serviceChargeOwing,
          description: this.s_serviceChargeOwingDescription,
        };
      },
      set(value) {
        this[`${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_OWING}`](
          value.answer,
        );
        this[
          `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_OWING_DESCRIPTION}`
        ](value.description);
      },
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      buildingInsuranceOptions,
    };
  },
  methods: {
    ...mapMutations([
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_BUILDING_INSURANCE}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_EXTERNAL_DECORATED}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_INTERNAL_DECORATED}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SELLER_MAINTENANCE_CONTRIBUTION}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_DECORATION_EXPENSES}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_DECORATION_EXPENSES_DESCRIPTION}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_MANAGEMENT_PROBLEMS}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_MANAGEMENT_PROBLEMS_DESCRIPTION}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE_DESCRIPTION}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE_DESCRIPTION}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES_DESCRIPTION}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_OWING}`,
      `${maintenanceMutations.MAINTENANCE_PREFIX}${maintenanceMutations.MAINTENANCE_SET_SERVICE_CHARGE_OWING_DESCRIPTION}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      await patchMaintenance(
        this.s_buildingId,
        this.s_buildingInsurance,
        this.s_externalDecorated,
        this.s_internalDecorated,
        this.s_sellerMaintenanceContribution,
        this.s_decorationExpenses,
        this.s_decorationExpensesDescription,
        this.s_managementProblems,
        this.s_managementProblemsDescription,
        this.s_buildingSafetyRiskExistence,
        this.s_buildingSafetyRiskExistenceDescription,
        this.s_serviceChargeChallenge,
        this.s_serviceChargeChallengeDescription,
        this.s_serviceChargeCollectionDifficulties,
        this.s_serviceChargeCollectionDifficultiesDescription,
        this.s_serviceChargeOwing,
        this.s_serviceChargeOwingDescription,
      );
      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <h2 class="col-md-12">Maintenance and service charges</h2>

      <!-- Set page validation wrapper -->
      <ValidationObserver ref="observer">
        <!-- Question 1 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="buildingInsuranceOptions"
            labelName="buildingInsuranceLabelName"
            title="Who is responsible for arranging the buildings insurance on the property?"
            v-model="buildingInsurance"
            validationFieldName="building Insurance"
          />
        </div>
        <!-- /question 1 end -->

        <div class="border-top my-3"></div>

        <!-- Question 2 -->
        <div class="col-md-12">
          <free-type-year-question
            title="In what year was the outside of the building last decorated?"
            labelName="externalDecoratedLabelName"
            v-model="externalDecorated"
            validationFieldName="external decorated"
            :required="true"
            :not-known="true"
          />
        </div>
        <!-- /question 2 end -->

        <div class="border-top my-3"></div>

        <!-- Question 3 -->
        <div class="col-md-12">
          <free-type-year-question
            :disabled="externalDecorated"
            title="In what year were any internal communal parts last decorated?"
            labelName="internalDecoratedLabelName"
            v-model="internalDecorated"
            validationFieldName="internal decorated"
            :required="true"
            :not-known="true"
          />
        </div>
        <!-- /question 3 end -->

        <div class="border-top my-3"></div>

        <!-- Question 4 -->
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="sellerMaintenanceContributionLabelName"
            title="Does the seller contribute to the cost of maintaining the building?"
            v-model="sellerMaintenanceContribution"
            validationFieldName="seller maintenance contribution"
          />
        </div>
        <!-- /question 4 end -->

        <div v-if="sellerMaintenanceContribution.answer">
          <!-- Question 5 -->
          <div class="border-top my-3"></div>
          <div class="col-md-12">
            <open-question
              :dropdownOptions="yesOrNo"
              dropdownName="decorationExpensesDropdownName"
              labelName="decorationExpensesLabelName"
              textAreaName="decorationExpensesTextAreaName"
              detailsName="decoration expenses details"
              title="Does the seller know of any expense (e.g. the cost of redecoration of outside or communal areas not usually incurred annually) likely to be shown in the service charge accounts within the next three years? If Yes, please give details:"
              v-model="decorationExpenses"
              validationFieldName="decoration expenses"
            />
          </div>
          <!-- /question 5 end -->

          <div class="border-top my-3"></div>

          <!-- Question 6 -->
          <div class="col-md-12">
            <open-question
              :dropdownOptions="yesOrNo"
              dropdownName="managementProblemsDropdownName"
              labelName="managementProblemsLabelName"
              textAreaName="managementProblemsTextAreaName"
              detailsName="management problems details"
              title="Does the seller know of any problems in the last three years regarding the level of service charges or with the management? If Yes, please give details:"
              v-model="managementProblems"
              validationFieldName="management problems"
            />
          </div>
          <!-- /question 6 end -->

          <div class="border-top my-3"></div>

          <!-- Question 7 -->
          <div class="col-md-12">
            <open-question
              :dropdownOptions="yesOrNo"
              dropdownName="serviceChargeChallengeDropdownName"
              labelName="serviceChargeChallengeLabelName"
              textAreaName="serviceChargeChallengeTextAreaName"
              detailsName="service charge challenge details"
              title="Has the seller challenged the service charge or any expense in the last three years? If Yes, please give details:"
              v-model="serviceChargeChallenge"
              validationFieldName="service charge challenge"
            />
          </div>
          <!-- /question 7 end -->

          <div class="border-top my-3"></div>

          <!-- Question 8 -->
          <div class="col-md-12">
            <open-question
              :dropdownOptions="yesOrNo"
              dropdownName="buildingSafetyRiskExistenceDropdownName"
              labelName="buildingSafetyRiskExistenceLabelName"
              textAreaName="buildingSafetyRiskExistenceTextAreaName"
              detailsName="building safety risk details"
              title="Does the seller know of the existence or suspected existence in the building of cladding or any defects that may create a building safety risk? If Yes, please give details:"
              v-model="buildingSafetyRiskExistence"
              validationFieldName="building safety risk"
            />
          </div>
          <!-- /question 8 end -->

          <div class="border-top my-3"></div>

          <!-- Question 9 -->
          <div class="col-md-12">
            <open-question
              :dropdownOptions="yesOrNo"
              dropdownName="serviceChargeCollectionDifficultiesDropdownName"
              labelName="serviceChargeCollectionDifficultiesLabelName"
              textAreaName="serviceChargeCollectionDifficultiesTextAreaName"
              detailsName="service charge collection difficulties details"
              title="Is the seller aware of any difficulties encountered in collecting the service charges from other flat owners? If Yes, please give details:"
              v-model="serviceChargeCollectionDifficulties"
              validationFieldName="service charge collection difficulties"
            />
          </div>
          <!-- /question 9 end -->

          <div class="border-top my-3"></div>

          <!-- Question 10 -->
          <div class="col-md-12">
            <open-question
              :dropdownOptions="yesOrNo"
              dropdownName="serviceChargeOwingDropdownName"
              labelName="serviceChargeOwingLabelName"
              textAreaName="serviceChargeOwingTextAreaName"
              detailsName="service charge owing details"
              title="Does the seller owe any service charges, rent, insurance premium or other financial contribution? If Yes, please give details:"
              v-model="serviceChargeOwing"
              validationFieldName="service charge owing"
            />
          </div>
          <!-- /question 10 end -->
        </div>
      </ValidationObserver>
      <!-- /set page validation wrapper -->
    </div>
  </div>
</template>
