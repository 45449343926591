import axios from 'axios';

export default (
  id,
  leaseSupplementalDeeds,
  landlordRegulations,
  correspondence,
  maintenanceInvoices,
  groundRentInvoices,
  sellerBuildingsInsurance,
  landlordBuildingsInsurance,
  tenantsManagementCompany,
  managementMemorandum,
  managementCertificate,
  managementAccounts,
) => {
  let preparedData = {
    lease_supplemental_deeds: leaseSupplementalDeeds,
    landlord_regulations: landlordRegulations,
    correspondence: correspondence,
    maintenance_invoices: maintenanceInvoices,
    ground_rent_invoices: groundRentInvoices,
    seller_buildings_insurance: sellerBuildingsInsurance,
    landlord_buildings_insurance: landlordBuildingsInsurance,
    tenants_management_company: tenantsManagementCompany,
  };

  preparedData.management_memorandum = tenantsManagementCompany ? managementMemorandum : null;
  preparedData.management_certificate = tenantsManagementCompany ? managementCertificate : null;
  preparedData.management_accounts = tenantsManagementCompany ? managementAccounts : null;

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/buildings/${id}`, { relevant_document: preparedData });
};
